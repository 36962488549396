<div class="row card-row login-wrap-row">
    <div class="col login-wrap-col">
        <div class="row heading">
            <h6>{{"PASSWORD_FORGOT_Q" | translate }}</h6>
        </div>
        <div class="row form-wrap">
            <form [formGroup]="formInfo" (ngSubmit)="submitForm()" class="login">
                <div class="row">
                    <div class="col">
                        <input type="text" nbInput fullWidth placeholder="{{'EMAIL_ADDRESS' | translate}}"
                            formControlName="username" autocorrect="off" autocapitalize="none">
                    </div>
                </div>
                <div class="row err-row" *ngIf="loadObj && loadObj.loadError">
                    <div class="col">
                       <span><nb-icon icon="alert-triangle-outline"></nb-icon>{{loadObj.errorString}}</span>
                    </div>
                </div>
                <div class="row success-row" *ngIf="loadObj && loadObj.loaded">
                    <div class="col">
                       <span><nb-icon icon="checkmark-circle-2-outline"></nb-icon>{{loadObj.loadedMessage}}</span>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col" style="text-align: center;">
                        <button nbButton status="primary" type="submit"
                            [disabled]="!formInfo.valid">{{"PASSWORD_RESET_GENERATE_PASSWORD_TOKEN" |
                            translate}}</button>    
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col">
                        <small class="signup">{{"ACCOUNT_ALREADY_EXISTS_Q" | translate}} <a routerLink="/login"><strong>{{"LOGIN" | translate}}</strong></a></small><br />
                        <small class="signup">{{"PASSWORD_RESET_ALREADY_HAVE_TOKEN" | translate}} <a routerLink="/reset-password"><strong>{{"PASSWORD_RESET" | translate}}</strong></a></small>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>