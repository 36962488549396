import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class FilterService {

  private FiltersAnnouncer = new Subject<any>();
  filters$ = this.FiltersAnnouncer.asObservable();

  private RatingAnnouncer = new Subject<any>();
  rating$ = this.RatingAnnouncer.asObservable();

  constructor() {}

  announceFilters(filters: any) {
    this.FiltersAnnouncer.next(filters);
  }

  announceRating(rating: number) {
    this.RatingAnnouncer.next(rating);
  }
  
}