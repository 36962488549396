import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule } from '@nebular/theme';

import { MonthlyComponent } from './monthly.component';

import { GlowService } from '../../../glow.service';
import { DateFormatService } from '../../../date-format.service';

import { HighchartsChartModule } from 'highcharts-angular';


@NgModule({
  declarations: [ MonthlyComponent ],
  imports: [
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    HighchartsChartModule
  ],
  providers: [
    DateFormatService,
    GlowService
  ],
  exports: [MonthlyComponent]
})
export class MonthlyModule { }
