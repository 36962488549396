<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Unbiased</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>No commission</h6>
        <p>No guessing how much you consume, no commission paid if we find you a suitable tariff.</p>
        <p>The FAST tool works by retrieving your energy consumption history from your smart
            meters; we use your actual smart meter data to deliver accurate energy quotes and make recommendations
            tailored to your individual needs.  On the results screen, you will see a breakdown of your consumption
            overlaid with actual smart tariffs to identify how making small changes could make a big difference to your
            bills.</p>
        <p>We hold your data only for the purpose of making the comparison; we do not sell it to anyone. If you create
            an account, it is so you can use the tool more quickly to get results in future.</p>
        <p>Our only goal is to help energy consumers like you appreciate how you might be able to save money, and help
            the planet, by switching to a <a href="/glossary/10">smart tariff</a>.</p>


    </div>
</div>