import { NgModule } from '@angular/core';
import { GlowService } from './glow.service';
// import { GlowConfigurationService } from './glow-config.service';
import { CacheService } from './cache.service';
import { DateFormatService } from './date-format.service';
import { JwtModule } from '@auth0/angular-jwt';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}
@NgModule({
    declarations: [
    ],
    imports: [
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
    ],
    exports: [
    ],
    providers: [
        CacheService,
        DateFormatService,
        // GlowConfigurationService,
        GlowService,
    ]
})
export class GlowModule {
}
