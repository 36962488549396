import { FormControl, ValidatorFn, FormArray } from '@angular/forms';
// import * as owaspTest from "owasp-password-strength-test";

export class FormValidator {

    // errorMessages = {
    //     "EMAIL_NO_CAPS": "Email should not contain Caps",
    //     "EMAIL_NO_SPACES": "Email should not have spaces",
    //     "EMAIL_NOT_VALID_EMAIL": "Not a valid email address",  
    //     "PASSWORD_STRENGTH_CHARACTER_LOWERCASE": "The password must contain at least one lowercase letter.",
    //     "PASSWORD_STRENGTH_CHARACTER_NUMBER": "The password must contain at least one number.",
    //     "PASSWORD_STRENGTH_CHARACTER_SPECIAL": "The password must contain at least one special character.",
    //     "PASSWORD_STRENGTH_CHARACTER_UPPERCASE": "The password must contain at least one uppercase letter.",
    //     "PASSWORD_STRENGTH_MAX_LENGTH": "The password must be at most 128 characters long.",
    //     "PASSWORD_STRENGTH_MAX_SEQUENCE": "The password may not contain sequences of three or more repeated characters.",
    //     "PASSWORD_STRENGTH_MIN_LENGTH": "The password must be at least 10 characters long.",
    //     "PASSWORDS_MISMATCH": "The two passwords do not match",  
    //     "USERNAME_NO_CAPS": "Username should not contain CAPS",
    //     "USERNAME_NO_SPACES": "Username should not have spaces",
    //     "USERNAME_NOT_VALID_EMAIL": "Username needs to be a valid email address",
    // };
    static isUsernameValid(usernameControl: FormControl): any {
        console.log(usernameControl)
        var errorList = {};
        var numberOfErrors = 0;
        if (usernameControl.value != usernameControl.value.toLowerCase()) {
            errorList["USERNAME_NO_CAPS"] = {
                "errMsg": "Username should not contain CAPS.",
                "error": true
            };
            numberOfErrors += 1;
        }

        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        //Source of regular expression http://www.regular-expressions.info/email.html
        if (!re.test(usernameControl.value)) {

            errorList["USERNAME_NOT_VALID_EMAIL"] = {
                "errMsg": "Username needs to be a valid email address.",
                "error": true
            };
            numberOfErrors += 1;

        }
        if (/\s/.test(usernameControl.value)) {

            errorList["USERNAME_NO_SPACES"] = {
                "errMsg": "Username should not have spaces.",
                "error": true
            };
            numberOfErrors += 1;

        }
        if (numberOfErrors > 0) {
            return errorList;
        }
        return null;
    }

    static isEmailValid(emailControl: FormControl): any {

        var errorList = {};
        var numberOfErrors = 0;
        if (emailControl.value != emailControl.value.toLowerCase()) {
            errorList["EMAIL_NO_CAPS"] = {
                "errMsg": "Email should not contain CAPS.",
                "error": true
            };
            numberOfErrors += 1;
        }

        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        // console.log("RE: ", re.test(emailControl.value))

        //Source of regular expression http://www.regular-expressions.info/email.html
        if (!re.test(emailControl.value)) {

            errorList["EMAIL_NOT_VALID_EMAIL"] = {
                "errMsg": "Not a valid email address.",
                "error": true
            };
            numberOfErrors += 1;

        }

        if (/\s/.test(emailControl.value)) {

            errorList["EMAIL_NO_SPACES"] = {
                "errMsg": "Email should not have spaces.",
                "error": true
            };
            numberOfErrors += 1;

        }

        if (numberOfErrors > 0) {
            return errorList;
        }
        return null;
    }

    static isPasswordSame(registrationInfo: FormControl) {

        if (registrationInfo) {
            if (registrationInfo.value) {
                if (registrationInfo.root['controls'].password.value === registrationInfo.value) {
                    console.log("passwords match")
                    return;
                }
            }
        }
        console.log("passwords do not match")
        var errorList = {
            "PASSWORDS_MISMATCH": {
                "errMsg": "The two passwords do not match.",
                "error": true
            }
        }
        return errorList;
    }

    static isNewPasswordSame(formInfo: FormControl) {

        if (formInfo) {
            if (formInfo.value) {
                if (formInfo.root['controls'].newPassword.value === formInfo.value) {
                    console.log("passwords match")
                    return;
                }
            }
        }
        console.log("passwords do not match")
        var errorList = {
            "PASSWORDS_MISMATCH": {
                "errMsg": "The two passwords do not match.",
                "error": true
            }
        }
        return errorList;
    }
    static isPasswordStrong(registrationInfo: FormControl) {
        var errorList = {}
        var passwordMinLength = 7;
        var passwordMaxLength = 128;

        var numberOfErrors = 0;
        if (registrationInfo.value) {
            var password = registrationInfo.value;
            console.log("PASSWORD LENGTH" + password.length)
            if (password.length < passwordMinLength) {

                errorList["PASSWORD_STRENGTH_MIN_LENGTH"] = {
                    "errMsg": "The password must be at least 7 characters long.",
                    "error": true
                };
                numberOfErrors += 1;
            }

            if (password.length > passwordMaxLength) {
                errorList["PASSWORD_STRENGTH_MAX_LENGTH"] = {
                    "errMsg": "The password must be at most 128 characters long.",
                    "error": true
                };
                numberOfErrors += 1;
            }
            if (/(.)\1{2,}/.test(password)) {
                errorList["PASSWORD_STRENGTH_MAX_SEQUENCE"] = {
                    "errMsg": "The password may not contain sequences of three or more repeated characters.",
                    "error": true
                };
                numberOfErrors += 1;
            }
            if (!/[a-z]/.test(password)) {
                errorList["PASSWORD_STRENGTH_CHARACTER_LOWERCASE"] = {
                    "errMsg": "The password must contain at least one lowercase letter.",
                    "error": true
                };
                numberOfErrors += 1;
            }
            if (!/[A-Z]/.test(password)) {
                errorList["PASSWORD_STRENGTH_CHARACTER_UPPERCASE"] = {
                    "errMsg": "The password must contain at least one uppercase letter.",
                    "error": true
                };
                numberOfErrors += 1;
            }
            if (!/[0-9]/.test(password)) {
                errorList["PASSWORD_STRENGTH_CHARACTER_NUMBER"] = {
                    "errMsg": "The password must contain at least one number.",
                    "error": true
                };
                numberOfErrors += 1;
            }
            // if (!/[^A-Za-z0-9]/.test(password)) {
            //     errorList["PASSWORD_STRENGTH_CHARACTER_SPECIAL"] = {
            //         "errMsg":  "The password must contain at least one special character.",
            //         "error": true
            //     };
            //     numberOfErrors += 1;
            // }

            console.log("Password Strength: " + numberOfErrors)
            if (numberOfErrors > 0) {
                return errorList;
            }
            return null;
        }
    }
    // Move to hardware validators.
    static isMACValid(hardwareDetailsForm: FormControl) {

        if (hardwareDetailsForm) {
            if (hardwareDetailsForm.value) {
                if (hardwareDetailsForm.value.length == 12) {
                    return;
                }
            }
        }
        console.log("MAC format is not valid")

        return { message: "MAC format is not valid", "InvalidMAC": true }
    }

    static isSerialValid(hardwareDetailsForm: FormControl) {
        if (hardwareDetailsForm) {
            if (hardwareDetailsForm.value) {
                if (hardwareDetailsForm.value.length > 6) {
                    // this.enterSerial = true;                    
                    return;
                }
            }
        }
        console.log("Serial Number format is not valid")
        // this.enterSerial = false;                       

        return { message: "Serial Number format is not valid", "InvalidSerialNumber": true }
    }


    static minSelectedCheckboxes(min = 1) {
        const validator: ValidatorFn = (formArray: FormArray) => {
            const totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(control => control.value)
                // total up the number of checked checkboxes
                .reduce((prev, next) => next ? prev + next : prev, 0);

            // if the total is not greater than the minimum, return the error message
            return totalSelected >= min ? null : { required: true };
        };

        return validator;
    }
}