import { Component, OnInit } from '@angular/core';
import { StscEngineService } from '../../stsc-engine.service';


@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  suppliers;

  constructor(public supplierService: StscEngineService) { }

  ngOnInit(): void {
    this.suppliers = this.supplierService.suppliers.suppliers;
    console.log(this.suppliers);
  }

  viewTariffs(i) {

  }

}
