import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { GenerateResetPasswordTokenComponent } from './page/generate-reset-password-token/generate-reset-password-token.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: './page/home/home.module#HomeModule'
  },
  {
    path: 'compare',
    loadChildren: './page/compare/compare.module#CompareModule'
  },
  {
    path: 'results',
    loadChildren: './page/results/results.module#ResultsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'faqs/:id',
    loadChildren: './page/faqs/faqs.module#FaqsModule'
  },
  {
    path: 'smart-tariff',
    loadChildren: './page/smart-tariff/smart-tariff.module#SmartTariffModule'
  },
  {
    path: 'terms-conditions',
    loadChildren: './page/terms-conditions/terms-conditions.module#TermsConditionsModule'
  },
  {
    path: 'feedback',
    loadChildren: './page/feedback/feedback.module#FeedbackModule'
  },
  {
    path: 'who-we-are',
    loadChildren: './page/who-we-are/who-we-are.module#WhoWeAreModule'
  },
  {
    path: 'unbiased',
    loadChildren: './page/unbiased/unbiased.module#UnbiasedModule'
  },
  {
    path: 'what-next',
    loadChildren: './page/what-next/what-next.module#WhatNextModule'
  },
  {
    path: 'glossary/:id',
    loadChildren: './page/glossary/glossary.module#GlossaryModule'
  },
  {
    path: 'your-data-use',
    loadChildren: './page/your-data-use/your-data-use.module#YourDataUseModule'
  },
  {
    path: 'stop-data-collection',
    loadChildren: './page/stop-data-collection/stop-data-collection.module#StopDataCollectionModule'
  },
  {
    path: 'your-data-rights',
    loadChildren: './page/your-data-rights/your-data-rights.module#YourDataRightsModule'
  },
  {
    path: 'cookies',
    loadChildren: './page/cookies/cookies.module#CookiesModule'
  },
  {
    path: 'login',
    loadChildren: './page/login/login.module#LoginModule'
  },
  {
    path: 'generate-password-token',
    loadChildren: './page/generate-reset-password-token/generate-reset-password-token.module#GenerateResetPasswordTokenModule'
  },
  {
    path: 'reset-password',
    loadChildren: './page/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'verify',
    loadChildren: './page/verify/verify.module#VerifyModule'
  },
  {
    path: 'supplier',
    loadChildren: './page/supplier/supplier.module#SupplierModule'
  },
  {
    path: 'products',
    loadChildren: './page/products/products.module#ProductsModule'
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
