import { NgModule } from '@angular/core';

import { ObjToKeysPipe } from './obj-to-key.pipe';
import { ReversePipe } from './reverse-array.pipe';


const PIPES = [
    ObjToKeysPipe,
    ReversePipe
];

@NgModule({
    imports: [],
    declarations: [...PIPES],
    providers: [],
    exports: [...PIPES]
})
export class PipesModule {
}