import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})

export class WhoWeAreComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }
}