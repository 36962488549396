import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable, throwError, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatService } from './date-format.service';
import { tAddAccountResponse, tApiErrorResponse, tForgottenPasswordResponse, tIsValid, tUserAction, tUserActionResponse } from './glow.typings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CacheService } from './cache.service';

@Injectable()
export class GlowService {


    private alternateApplicationId: string = 'b0f1b774-a586-4f72-9edd-27ead8aa7a8d' // bryt
    private applicationId: string = '6dc7e58b-4f10-4897-9a6b-a0971bee1235' // stsc

    private hostUrl: string = 'https://api.glowmarkt.com/api/v0-1/'
    private tempAccessDirectoryId: string = 'f9f89b6f-db18-4c0a-b82b-cd675bc4c8cf';

    public getAuthEvent: EventEmitter<any> = new EventEmitter();

    private token: string
    private resources: any[]
    constructor(
        private dateFormatService: DateFormatService,
        public jwtHelper: JwtHelperService,
        private http: HttpClient,
        private storage: CacheService
    ) {

    }

    // ****************** API checks *********************/
    performNetworkCheck(): tApiErrorResponse {
        let response = {
            isError: false,
            messages: [],
            data: ''
        }
        // if (this.networkActive === false) {
        //   response.isError = true
        //   response.messages = ['ERROR_NO_NETWORK']
        // }
        return response;
    }


    performPreAPIChecksWithAuth(): tApiErrorResponse {
        let response = {
            isError: false,
            messages: [],
            data: ''
        }
        const authCheck = this.performAuthCheck();
        const networkCheck = this.performNetworkCheck();
        if (authCheck.isError === true) {
            response.isError = true
            response.messages = response.messages.concat(authCheck.messages)
        }
        if (networkCheck.isError === true) {
            response.isError = true
            response.messages = response.messages.concat(networkCheck.messages)
        }
        return response;
    }

    performAuthCheck(): tApiErrorResponse {
        let response = {
            isError: false,
            messages: [],
            data: ''
        }
        if (this.checkExistingToken() === false) {
            response.isError = true
            response.messages = ['ERROR_TOKEN_NOT_VALID']
        }
        return response
    }


    performPreAPIChecksWithoutAuth(): tApiErrorResponse {
        return this.performNetworkCheck()
    }

    //******************** Headers *************/
    private getNoAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'applicationId': this.applicationId });
        return headers
    }

    private getAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'applicationId': this.applicationId,
            'token': localStorage.getItem('id_token') || this.token,
            'X-GLOW-Version': '0'
        });
        return headers
    }

    private getNoAuthOptions() {
        const headers = this.getNoAuthHeaders()
        return { headers: headers }
    }

    private getAuthOptions() {
        const headers = this.getAuthHeaders()
        return { headers: headers }
    }
    // ***************LOGIN*****************
    //accountInfo: {username, password}
    public login({ username, password }): Observable<boolean> {
        this.resources = null
        console.log('Log in');
        const accountInfo = {
            username,
            password,
            ...username.split('@')[1] === 'null.com' && { directoryId: this.tempAccessDirectoryId }
        }

        return this.http.post(this.hostUrl + 'auth/', JSON.stringify(accountInfo), this.getNoAuthOptions())
            .pipe(
                map((res) => this.setToken(res)),
                catchError((error: any) => throwError(error))
            )
    }

    setDefaultOffset() {
        return new Date().getTimezoneOffset();
    }

    public addAccount(payload): Observable<tAddAccountResponse> {
        console.log('Add account')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            payload.applicationId = this.alternateApplicationId;
            return this.http.post<tAddAccountResponse>(this.hostUrl + 'user/account', payload, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public triggerForgottenPasswordProcess(triggerForgottenPasswordInfo): Observable<tForgottenPasswordResponse> {
        console.log('Generate Password Reset Token')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            triggerForgottenPasswordInfo.applicationId = this.applicationId;
            return this.http.post<tForgottenPasswordResponse>(this.hostUrl + 'user/resetpassword', triggerForgottenPasswordInfo, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public resetPassword(resetPasswordInfo): Observable<tIsValid> {
        console.log('Password Reset')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            resetPasswordInfo.applicationId = this.applicationId;
            return this.http.put<tIsValid>(this.hostUrl + 'user/resetpassword', resetPasswordInfo, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public migrateDataToSTSC(): Observable<any> {
        console.log('Migrate data to smart tariff')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            return this.http.post<any>(this.hostUrl + 'provision/new-account/data-migration', { "preexistingApplicationId": "b0f1b774-a586-4f72-9edd-27ead8aa7a8d" }, this.getAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public isAuthenticated(): Observable<boolean> {
        return of(this.checkExistingToken());
    }

    public checkExistingToken(): boolean {
        const loggedIn = this.isLoggedIn()

        if (loggedIn === false) {
            this.emitLogOut();
        }
        return loggedIn;
    }

    public isLoggedIn(): boolean {
        const token = localStorage.getItem('id_token');
        if (token) {
            if (this.jwtHelper.isTokenExpired(token) == false) {
                return true;
            } else {
                console.warn('Token expired');
            }
        } else {
            console.warn('Token non-existent');
        }
        return false;
    }

    emitLogIn() {
        console.log('emitting login ')
        this.getAuthEvent.emit('user:loggedIn');
    }

    emitLogOut() {
        console.log('emitting logout')
        this.getAuthEvent.emit('user:logout');
    }

    public logout(): Observable<tIsValid> {
        console.log('Logging Out')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            // To log out, delete token from server side, and remove the token and profile from local Storage
            const options = this.getAuthOptions();
            this.deleteJwt();
            this.emitLogOut();
            return this.http.delete<tIsValid>(this.hostUrl + 'auth/deleteToken', options)
        } else {
            return throwError(response);
        }
    }

    public verify(verificationInfo): Observable<tIsValid> {
        console.log('Verifying Account')
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            verificationInfo.applicationId = this.applicationId;
            return this.http.put<tIsValid>(this.hostUrl + 'user/verify', verificationInfo, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

    deleteJwt() {
        console.log('deleteJWT token from local storage')
        localStorage.removeItem('id');
        localStorage.removeItem('id_token');
        localStorage.removeItem('name');
        localStorage.removeItem('isTempAuth');
        this.storage.remove('selectedVeApplicationDetails'); // Should not be here
        return;

    }


    // ***************Daily Pattern*****************
    public async getDailyElecConsumption(date: Date) {
        const resource = await this.getElectricityConsumptionResource()
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec consumption resource available")
        }
        const fromDate = this.dateFormatService.apiTimeFromFormat(date)
        const toDate = this.dateFormatService.apiTimeToFormat(date)
        console.log(`Requesting data ${resource.resourceId} ${fromDate}`)
        return this.getResourceReadings(resource.resourceId, fromDate, toDate).toPromise()
    }

    // ***************Annual Consumption/Cost*****************
    public async getAnnualUsage(dimension) {
        let resource;
        if (dimension === 'cost') {
            resource = await this.getElectricityCostResource()
        } else {
            resource = await this.getElectricityConsumptionResource()
        }
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec resource available")
        }
        const { from, to } = this.dateFormatService.get12MonthSlidingWindow()
        console.log(`Requesting data ${resource.resourceId} ${from} ${to}`)
        return this.getResourceReadings(resource.resourceId, from, to, 'P1M').toPromise()
    }


    // ***************Tariff*****************
    public async getElecTariff() {
        const resource = await this.getElectricityConsumptionResource()
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec resource available")
        }
        const tariffResource = await this.getResourceTariff(resource.resourceId).toPromise()
        console.log(tariffResource)
        return this.formatTariff(tariffResource)
    }

    private async getElectricityConsumptionResource() {
        const classifier = 'electricity.consumption'
        return this.getResourceByClassifier(classifier)
    }

    private async getElectricityCostResource() {
        const classifier = 'electricity.consumption.cost'
        return this.getResourceByClassifier(classifier)
    }

    private async getResourceByClassifier(classifierFilter): Promise<any> {
        const resources = await this.getResources().toPromise()
        return resources.find(resource => resource.classifier === classifierFilter)
    }

    private getResources(): Observable<any[]> {
        console.log('Get resources ' + this.applicationId);
        if (Array.isArray(this.resources) && this.resources.length > 0) {
            return of(this.resources)
        } else {
            return this.http.get<any[]>(this.hostUrl + 'resource', this.getAuthOptions())
        }
    }

    getResourceReadings(resourceId: string, fromDate: string, toDate: string, period: string = "PT30M", func: string = 'sum') {
        const offset = this.setDefaultOffset();
        let url = this.hostUrl + 'resource/' + resourceId + '/readings?nulls=1&from=' + fromDate + '&to=' + toDate + '&period=' + period + '&function=' + func;
        if (offset && offset != 0) {
            url += '&offset=' + offset;
        }
        return this.http.get(url, this.getAuthOptions())
    }

    getResourceTariff(resourceId: string) {
        let url = this.hostUrl + 'resource/' + resourceId + '/tariff?';
        return this.http.get(url, this.getAuthOptions())
    }

    private formatTariff(resourceTariff) {
        let tariffInfo = this.setTariffElement(resourceTariff)
        if (resourceTariff.data && resourceTariff.data.length > 0) {
            const [tariffElem] = resourceTariff.data
            if (tariffElem.currentRates) {
                tariffInfo.rate = tariffElem.currentRates.rate;
                tariffInfo.standingCharge = tariffElem.currentRates.standingCharge;
            }
            tariffInfo.type = tariffElem.type;
            tariffInfo.futureRates = tariffElem.futureRates;
        } else {
            tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_NOT_FOUND' }
        }
        return tariffInfo
    }

    setTariffElement({ classifier = null, resourceId = null, rate = null, standingCharge = null, futureRates = null, type = null, hasError = false, errorString = null }) {
        return { classifier, resourceId, rate, standingCharge, hasError, errorString, futureRates, type }
    }

    private setToken(data: any): boolean {
        console.log('setToken data', data)
        if (data.token) {
            this.token = data.token
            localStorage.setItem('id_token', data.token);
            localStorage.setItem('name', data.name);
            this.emitLogIn();
            return true
        }
        throw new Error("no token")
    }

    isTempAuth(): boolean {
        return (localStorage.getItem('isTempAuth') === 'true') ? true : false
    }


    // User actions
    getUserActions(userActionRef: string): Observable<tUserAction[]> {
        console.log('Send User Action');
        const response = this.performPreAPIChecksWithAuth();

        if (response && response.isError === false) {
            let url;
            if (userActionRef) {
                url = this.hostUrl + 'useraction?reference=' + userActionRef;
            } else {
                url = this.hostUrl + 'useraction';
            }
            return this.http.get<tUserAction[]>(url, this.getAuthOptions())
        } else {
            return throwError(response);
        }
    }

    sendUserAction(userActionBody): Observable<tUserActionResponse> {
        console.log('Send User Action');
        const response = this.performPreAPIChecksWithAuth();
        if (response && response.isError === false) {
            const url = this.hostUrl + 'useraction';
            return this.http.post<tUserActionResponse>(url, userActionBody, this.getAuthOptions())
        } else {
            return throwError(response);
        }
    }

    getResultLoadTime(): Observable<any>  {
        console.log('Get Result Load Time');
        const response = this.performPreAPIChecksWithAuth();
        if (response && response.isError === false) {
            return this.http.get('https://beta.smarttariffsmartcomparison.org/loadtime.php', this.getAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public submitFeedback(payload: any): Observable<any> {
        console.log('submitFeedback', payload)
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            return this.http.post<any>('https://beta.smarttariffsmartcomparison.org/feedback.php', payload, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

    public submitWhatNext(payload: any): Observable<any> {
        console.log('submitWhatNext', payload)
        const response = this.performPreAPIChecksWithoutAuth()
        if (response && response.isError === false) {
            return this.http.post<any>('https://beta.smarttariffsmartcomparison.org/whatnext.php', payload, this.getNoAuthOptions())
        } else {
            return throwError(response);
        }
    }

}