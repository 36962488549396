import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-tariff-details',
  templateUrl: './tariff-details.component.html',
  styleUrls: ['./tariff-details.component.scss']
})

export class TariffDetailsComponent implements OnInit {

  @Input() data: any;
  unitrate: string
  standingcharge: string
  tarifftype: string

  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
    console.log('this.data',this.data)
    this.unitrate = (this.data.tariff._flat.rate).toFixed(2) + ' p/kWh'
    this.standingcharge = (this.data.tariff.standingcharge).toFixed(2) + ' p/kWh'
    this.tarifftype = this.data.tariff.type
  }

  ngOnDestroy() { }

  close() {
    this.dialogRef.close();
  }
}