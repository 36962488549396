import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stop-data-collection',
  templateUrl: './stop-data-collection.component.html',
  styleUrls: ['./stop-data-collection.component.scss']
})

export class StopDataCollectionComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }
}