<div class="row card-row login-wrap-row">
    <div class="col login-wrap-col">
        <div class="row heading">
            <h6>{{"VERIFY_ACCOUNT" | translate }}</h6>
        </div>
        <div class="row form-wrap">

            <div class="row" style="margin-bottom: 15px; font-weight: bold;"
                *ngIf="missingElements.length>0 || incorrectElements.length>0 || (loadObj && loadObj.loadError)">
                <div class="col"><span>Verification failed</span></div>
            </div>

            <!-- Warnings for missing url query parameters -->
            <div class="row err-row" *ngIf="missingElements.length>0">
                <div class="col">
                    <span>
                        <nb-icon icon="alert-triangle-outline"></nb-icon>Missing Elements: {{missingElements}}
                    </span>
                </div>
            </div>

            <div class="row err-row" *ngIf="incorrectElements.length>0">
                <div class="col">
                    <span>
                        <nb-icon icon="alert-triangle-outline"></nb-icon>Incorrect Elements: {{incorrectElements}}
                    </span>
                </div>
            </div>

            <div class="row err-row" *ngIf="loadObj && loadObj.loadError">
                <div class="col">
                    <span>
                        <nb-icon icon="alert-triangle-outline"></nb-icon>{{loadObj.errorString}}
                    </span>
                </div>
            </div>

            <div class="row" style="margin-top: 15px;"
                *ngIf="missingElements.length>0 || incorrectElements.length>0 || (loadObj && loadObj.loadError)">
                <div class="col">
                    <span>Please contact <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a></span>
                </div>
            </div>

            <div class="row success-row" *ngIf="loadObj && loadObj.loadedMessage">
                <div class="col">
                    <span>
                        <nb-icon icon="alert-triangle-outline"></nb-icon>{{loadObj.loadedMessage}}
                    </span>
                </div>
            </div>

            <div class="row" style="margin-top: 20px;">
                <div class="col">
                    <small class="signup">{{"ACCOUNT_ALREADY_EXISTS_Q" | translate}} <a
                            (click)="navigateLogin()"><strong>{{"LOGIN" | translate}}</strong></a></small>
                </div>
            </div>

        </div>
    </div>
</div>