import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GlowService } from '../../glow.service';
import { NbThemeService, NbMediaBreakpoint, NbMediaBreakpointsService, NbDialogService } from '@nebular/theme';
import { Options } from '@angular-slider/ngx-slider';
import { StscTrustComponent } from '../../stsc-trust/stsc-trust.component';
import { StscBadgeComponent } from '../../stsc-badge/stsc-badge.component';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CustomLikeButtonComponent } from 'src/app/shared/custom-like-button';
import { StscEngineService } from 'src/app/stsc-engine.service';
import { FilterService } from 'src/app/filter.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { TariffDetailsComponent } from 'src/app/components/tariff-details/tariff-details.component';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { CsvDownloadService } from 'src/app/csv-download.service';
import { Timeseries } from 'stsc-tsc';
import { TOUTariffDetailsComponent } from 'src/app/components/tou-tariff-details/tou-tariff-details.component';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit {

  @ViewChild('table') table: Ng2SmartTableComponent;
  @HostListener('body:click', ['$event'])
  onClick(event: any) {
    if (
      (event.target && event.target.attributes.class && event.target.attributes['class'].nodeValue.indexOf("ng2-smart-page-link") >= 0) ||
      (event.target.parentElement && event.target.parentElement.attributes['class'] && event.target.parentElement.attributes['class'].nodeValue.indexOf("ng2-smart-page-link") >= 0)
    ) {
      // Send GA Event
      this.sendGAEvent({ event: 'nextPageResults' })
      this.reselectRows()
    }
  }

  breakpoint: NbMediaBreakpoint;
  breakpoints: any;
  themeSubscription: any;

  dataDaily: number;
  dailyMax: number;
  dailyMin: number;
  annualUsage: number;
  annualCost: number;
  weekDayMax: number;
  weekDayMin: number;
  weekendDayMax: number;
  weekendDayMin: number;
  flexScore: number;
  currentTariff;
  results: any;
  resultscount: number;
  filterResults: any;
  selectedResult: any = [];
  selectedTariff: any;
  annualCostArr: any;

  green_value: number;
  green_options: Options;
  price_value: number;
  price_options: Options;
  service_value: number;
  service_options: Options;

  matchCriteria: any;
  featuremask: number;
  features: Array<boolean>;
  maxfeatures: number;

  showResults: boolean = false
  showFilters: boolean = false
  showNoData: boolean = true

  showResultError: boolean = false
  loadingData: boolean = true
  timeRemaining: string
  loadTimeResponse: any
  loadtime: number

  sortorder: boolean = false;
  filterview: boolean = true;
  selectedGraphFilter: string = 'none'
  selectedGraphView: string = 'month'
  evChecked: boolean = false;
  interval: any

  postcode: string = ''

  featureQuery = ["smart",
    "green",
    "fixed",
    "variable",
    "tou",
    "dynamic",
    "contract_none",
    "contract_12",
    "contract_24",
    "exit",
    "solar",
    "ev",
    "heatpump",
    "smartelec",
    "smartgas",
    "smartprepay",
    "tradelec",
    "econ7",
    "econ10",
    "dd",
    "demand",
    "prepay"];

  url_query;

  priceOptionLabels = ["All about price", "It's not everything", "Not concerned", "Willing to pay more for what I want"];
  serviceOptionLabels = ["Service doesn't matter", "Willing to consider poor service rating", "Decent service rating", "Good service rating", "Service is overiding concern"];
  greenOptionLabels = ["Don't care", "Some level of green", "Offsets are OK", "100% green source", "Green ethos too"];

  monthlyConsumptionData;
  evMonthlyConsumptionData;

  settings = {
    mode: 'external',
    selectMode: 'multi',
    actions: {
      columnTitle: 'Actions',
      add: false,
      edit: false,
      delete: false,
      custom: []
    },
    hideHeader: false,
    hideSubHeader: true,
    pager: {
      display: true,
      page: 1,
      perPage: 10,
    },
    columns: {
      score: {
        title: 'Rating',
        type: 'custom',
        renderComponent: StscBadgeComponent
      },
      supplier: {
        title: 'Supplier',
        type: 'custom',
        sort: true,
        renderComponent: StscTrustComponent,
        compareFunction: (direction: any) => {
          this.sortResults(direction, 'brand_score')
        }
      },
      displayname: {
        title: 'Name',
        type: 'html',
        valuePrepareFunction: (val, row) => {
          return val + '<br/><span class="small">' + row.tariff.name + '</span>';
        }
      },
      monthly_cost: {
        title: 'Monthly Cost',
        sort: true,
        valuePrepareFunction: (cell, row) => {
          return ('£ ' + (row.annualcost > 0 ? Math.round(row.annualcost / 12) : 0));
        },
        compareFunction: (direction: any) => {
          this.sortResults(direction, 'annualcost')
        }
      },
      annualcost: {
        title: 'Annual Cost',
        valuePrepareFunction: (annualcost) => {
          return ('£ ' + Math.round(annualcost));
        }
      },
      likeUnlike: {
        title: 'Like?',
        type: 'custom',
        renderComponent: CustomLikeButtonComponent,
        filter: false,
        sort: false
      }
    }
  };
  updateChartFlag = false;

  graphFilters = [
    {
      displayName: 'Not at all',
      value: 'none'
    },
    {
      displayName: '10% shift',
      value: 'low'
    },
    {
      displayName: '20% shift',
      value: 'medium'
    },
    {
      displayName: '30% shift',
      value: 'high'
    },
    {
      displayName: 'Add Electric Vehicle',
      value: 'smart'
    }
  ]

  graphView = [
    {
      displayName: 'Monthly',
      value: 'month'
    },
    {
      displayName: 'Average Daily',
      value: 'day'
    }
  ]

  subscriptionFilters: Subscription;
  subscriptionRating: Subscription;
  subscriptionTariffs: Subscription;
  form: FormGroup;
  loadtimeObj: any

  constructor(
    private glowService: GlowService,
    private themeService: NbThemeService,
    private ccService: NgcCookieConsentService,
    private breakpointService: NbMediaBreakpointsService,
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialogService: NbDialogService,
    private http: HttpClient,
    private csvDownloadService: CsvDownloadService,
    private stscEngine: StscEngineService
  ) {
    this.matchCriteria = [];
    this.loadingData = true

    this.loadtimeObj = this.dataService.getLoadtime()
    console.log('this.loadtimeObj', this.loadtimeObj)
    if (!this.loadtimeObj) {
      this.loadTime()
    } else {
      if (this.loadtimeObj && this.loadtimeObj.loadtime < 120) {
        this.showResults = true;
        this.showFilters = true;
        this.showNoData = false;
        this.loadData().then(response => {
          console.log('Consumption data loaded', response)
          this.loadResults()
          this.sendGAEvent({ event: 'resultsAvailable', viewingResults: true, loadtime: this.loadtimeObj.loadtime })
          // if (this.filterResults) {
          //   this.selectTariff(this.filterResults[1])
          // }
        })
      } else {
        this.showResults = false;
        this.showFilters = false;
        this.showNoData = true;
        this.loadtime = this.loadtimeObj.loadtime
        this.refreshLoadTime(this.loadtimeObj.loadtime)
      }
    }

    this.subscriptionFilters = this.filterService.filters$.subscribe(
      filters => {
        console.log('filters', filters)
        this.toggleFeature(filters.event, filters.element, filters.cat, filters.item, filters.criteria)
      });

    this.subscriptionRating = this.filterService.rating$.subscribe(
      rating => {
        console.log('rating', rating)
        this.changeRating(rating)
      });

    this.subscriptionTariffs = this.stscEngine.allTariffs$.subscribe(
      tariffs => {
        this.loadResults()
      });

    this.form = this.formBuilder.group({
      flex: [''],
    })
  }

  ngOnInit(): void {

    this.postcode = localStorage.getItem('postcode')
    this.maxfeatures = 24;
    let features = new Array();

    this.breakpoints = this.breakpointService.getBreakpointsMap();
    this.themeSubscription = this.themeService.onMediaQueryChange()
      .subscribe(([oldValue, newValue]) => {
        this.breakpoint = newValue;
      });

    this.flexScore = 90;
    this.green_value = 3;
    this.service_value = 3;
    this.price_value = 2;

    for (var i = 0; i < this.maxfeatures; i++) {
      features.push(false);
    }

    this.features = features;

    this.price_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.priceOptionLabels[v];
      },
      stepsArray: [
        { value: 1, legend: "All about price" },
        { value: 2, legend: "It's not everything" },
        { value: 3, legend: "Not concerned" },
        { value: 4, legend: "Willing to pay more for what I want" },
      ]
    };
    this.service_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.serviceOptionLabels[v];
      },
      stepsArray: [
        { value: 1, legend: "Service doesn't matter" },
        { value: 2, legend: "Willing to consider poor service rating" },
        { value: 3, legend: "Decent service rating" },
        { value: 4, legend: "Good service rating" },
        { value: 5, legend: "Service is overiding concern" },
      ]
    };
    this.green_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.greenOptionLabels[v];
      },
      stepsArray: [
        { value: 1, legend: "Don't care" },
        { value: 2, legend: "Some level of green" },
        { value: 3, legend: "Offsets are OK" },
        { value: 4, legend: "100% green source" },
        { value: 5, legend: "Green ethos too" },
      ]
    };

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
    this.subscriptionFilters.unsubscribe();
    this.subscriptionRating.unsubscribe();
    if (this.interval) {
      console.log('clearInterval')
      clearInterval(this.interval);
    }
  }

  greenChange() {
    console.log("Changed green " + this.green_value);
    this.loadResults();
    this.sendGAEvent({
      event: 'slider',
      filterResults: 'greenEnergySlider',
      greenEnergyValue: this.green_value,
      sliderValue: this.green_value
    })
  }

  priceChange() {
    console.log("Changed price " + this.price_value);
    this.loadResults();
    this.sendGAEvent({
      event: 'slider',
      filterResults: 'lowestPriceSlider',
      lowestPriceValue: this.price_value,
      sliderValue: this.price_value

    })
  }

  serviceChange() {
    console.log("Changed service " + this.service_value);
    this.loadResults();
    this.sendGAEvent({
      event: 'slider',
      filterResults: 'customerServiceSlider',
      customerServiceValue: this.service_value,
      sliderValue: this.service_value
    })
  }

  sortResults(direction, field) {
    if (direction == 1) {
      this.results.sort((a, b) => (a[field] < b[field] ? 1 : -1))
    } else {
      this.results.sort((a, b) => (b[field] < a[field] ? 1 : -1))
    }
  }

  toggleFeature(event, element, cat, item, criteria) {
    this.features[element] = event;
    console.log(criteria);

    this.productFeature(event, criteria);

    this.sendGAEvent({
      event: 'filterresults',
      filterResults: this.featureQuery[element - 1],
      filterCategory: cat,
      filterItem: item,
      status: event ? 'on' : 'off'
    })
  }

  toggleSort(event) {
    this.sortorder = this.sortorder ? false : true;
    if (this.sortorder) {
      this.stscEngine.sortSmart();
      console.log("sort smart");
    } else {
      this.stscEngine.sortProductCost();
      console.log("sort cost");
    }
    this.sendGAEvent({ event: 'sortResults', sortBy: this.sortorder ? 'smart' : 'cost' })
    this.loadResults();
  }

  toggleFilter(value) {
    this.filterview = this.filterview ? false : true;
    this.sendGAEvent({ event: 'resultToggle', resultView: this.filterview ? 'bestThree' : 'viewAll' })
  }

  openRateModal(result) {
    this.dialogService.open(TariffDetailsComponent, { context: { data: result } });
  }

  openTOURateModal(result) {
    console.log('result', result)
    this.dialogService.open(TOUTariffDetailsComponent, { context: { data: result } });
  }

  async loadResults() {
    console.log('loadResults')
    if (this.stscEngine.products.tariffs.length !== 0) {
      this.calculateCostsRefreshTariffs();
      this.loadingData = false
    }
  }

  async loadData() {

    this.loadResults().then((response) => {
      console.log('Tariff data loaded')
      this.stscEngine.sortProductCost();
    }) // .catch(e => console.warn(e))

    this.dailyMax = 0;
    this.dailyMin = 9999;

    this.monthlyConsumptionData = JSON.parse(localStorage.getItem('monthlyConsumptionData'));

    if (!this.monthlyConsumptionData || !Array.isArray(this.monthlyConsumptionData)) {
      const annualUsage = await this.glowService.getAnnualUsage('energy')
      if (annualUsage && Array.isArray(annualUsage["data"])) {
        let tsData = [];
        annualUsage["data"].forEach(element => {
          tsData.push([element[0] * 1000, element[1]]);
        });
        this.monthlyConsumptionData = tsData;
        localStorage.setItem('monthlyConsumptionData', JSON.stringify(tsData));
      }
    }

    let monthlyCostData = JSON.parse(localStorage.getItem('monthlyCostData'));

    if (!monthlyCostData || !Array.isArray(monthlyCostData)) {
      this.annualCostArr = await this.glowService.getAnnualUsage('cost');
      if (this.annualCostArr && Array.isArray(this.annualCostArr["data"])) {
        let tsData = [];
        let annualCost = 0;
        this.annualCostArr["data"].forEach(element => {
          annualCost += element[1] / 100;
          tsData.push([element[0] * 1000, Math.round(element[1] / 100)]);
        });
        console.log("Got server cost data");
        monthlyCostData = tsData;
        localStorage.setItem('annualCost', JSON.stringify(annualCost));
        localStorage.setItem('monthlyCostData', JSON.stringify(tsData));
      }
    }
  }

  public selectTariff(result) {

    this.filterResults.filter(x => x.fkid === result.fkid).map(x => {
      x.selected = true
    });

    this.table.grid.getRows().forEach((row) => {
      if (row && row['data'] && row['data'].fkid && row['data'].fkid == result.fkid) {
        this.table.grid.multipleSelectRow(row);
        row.isSelected = true;
      }
    });

    this.selectedTariff = result;
    this.graphResults();
  }

  reselectRows() {
    // issue with ng2-smart-table - pagination loses selection so need to manually reselect
    this.table.grid.getRows().forEach((row) => {
      if (this.selectedResult.find(elem => elem.fkid === row.getData().fkid)) {
        this.table.grid.multipleSelectRow(row);
      }
    });
  }

  removeTariff(i, tariffId) {

    this.filterResults.filter(x => x.fkid === tariffId).map(x => {
      x.selected = false
    });

    this.table.grid.getRows().forEach((row) => {
      if (row && row['data'] && row['data'].fkid && row['data'].fkid == tariffId) {
        row.isSelected = false
      }
    });

    this.selectedResult.splice(i, 1);
    this.selectedResult = this.selectedResult.slice();
  }

  public graphResults() {
    console.log('graphResults ', this.selectedTariff);
    let myenergy = this.stscEngine.prediction;
    let cost = this.stscEngine.cost;
    this.stscEngine.tariff = this.selectedTariff.tariff;

    cost.init({ energy: myenergy, tariff: this.selectedTariff.tariff });

    if (this.stscEngine.tariff.name && cost.byMonth(null)) {

      const found = this.selectedResult.some(el => el.fkid === this.stscEngine.tariff.id);
      if (!found) {

        let selectedResultTotalAnnualCost = 0;

        cost.byMonth(null).forEach((element) => {
          selectedResultTotalAnnualCost += element[1];
        })

        let annualCost = JSON.parse(localStorage.getItem('annualCost'));
        let resultAmount = (annualCost - Math.round(selectedResultTotalAnnualCost));

        this.selectedResult.push({
          selectedTariff: this.selectedTariff,
          fkid: this.stscEngine.tariff.id,
          display_name: this.stscEngine.tariff.name,
          monthlycost: cost.byMonth(null),
          save: resultAmount > 0 ? true : false,
          annualCost: '£' + Math.abs(resultAmount).toFixed(0)
        })

        this.selectedResult = this.selectedResult.slice();
      } else {
        let key = this.selectedResult.findIndex(x => x.fkid === this.stscEngine.tariff.id)
        this.removeTariff(key, this.stscEngine.tariff.id)
      }
    }
  }

  async reloadGraphTariffs() {
    console.log('reloadGraphTariffs')

    const lastTariffSet = await this.selectedResult.reduce((accumulatorPromise, element) => {
      return accumulatorPromise.then(async response => {
        let myenergy = this.stscEngine.prediction;
        let cost = this.stscEngine.cost;
        this.stscEngine.tariff = element.selectedTariff.tariff

        cost.init({ energy: myenergy, tariff: this.stscEngine.tariff });

        let selectedResultTotalAnnualCost = 0;

        cost.byMonth(null).forEach((element) => {
          selectedResultTotalAnnualCost += element[1];
        })

        let annualCost = JSON.parse(localStorage.getItem('annualCost'));
        let resultAmount = (annualCost - Math.round(selectedResultTotalAnnualCost));

        element.monthlycost = cost.byMonth(null);
        element.annualCost = '£' + Math.abs(resultAmount).toFixed(0);
        return { valid: true }
      })
    }, Promise.resolve())

  }

  public graphFullResults(event) {
    this.selectTariff(event.data)
  }

  likeTariff(result) {
    result.liked = true
    this.sendGAEvent({ event: 'tariffLiked', tariff: result.fkid })
  }

  unlikeTariff(result) {
    result.liked = false
  }

  changeMobFlexibility(event) {
    console.log('changeMobFlexibility', event)
    if (event != '') {
      this.selectGraphFilter(event)
    }
  }

  // Efficiency toggle
  selectGraphFilter(value) {

    this.sendGAEvent({
      event: 'graphfilters',
      graphFilterItem: 'flexibility',
      graphFilterValue: value,
      flexibility: value
    })

    this.selectedGraphFilter = value;

    if (value != 'smart') {
      this.evChecked = false
    }

    switch (this.selectedGraphFilter) {
      case 'smart':
        this.scenarioEV()
        this.form.controls['flex'].setValue('')
        break;
      case 'none':
        this.scenarioClear()
        this.form.controls['flex'].setValue('')
        break;
      case 'low':
        this.scenarioFlex(1)
        break;
      case 'medium':
        this.scenarioFlex(2)
        break;
      case 'high':
        this.scenarioFlex(3)
        break;
    }

  }

  // Monthly vs Daily tabs
  selectGraphView(event) {
    let value = event.tabTitle == 'Average Daily' ? 'day' : 'month'
    this.selectedGraphView = value;
    this.sendGAEvent({
      event: 'graphView',
      graphViewItem: 'view',
      graphViewValue: value,
      view: value
    })
  }

  async scenarioClear() {
    this.stscEngine.clearPrediction();
    this.stscEngine.products.predictedEnergy = this.stscEngine.energy
    this.calculateCostsRefreshTariffs();
    this.reloadGraphTariffs();
  }

  async scenarioFlex(difficulty): Promise<void> {
    let percent = difficulty / 10;
    this.stscEngine.timeShiftEnergy(percent);
    this.stscEngine.products.predictedEnergy = this.stscEngine.prediction
    this.calculateCostsRefreshTariffs();
    this.reloadGraphTariffs();
  }

  async scenarioEV(): Promise<void> {
    this.stscEngine.addEV();
    let evConsumptiom = []
    let myenergy = this.stscEngine.prediction;
    let monthlyConsumptionData = JSON.parse(localStorage.getItem('monthlyConsumptionData'))

    myenergy.byMonth({ aggregateFunction: Timeseries.AGG_MEAN }).forEach(ev => {
      monthlyConsumptionData.forEach(element => {
        if (ev[0] == element[0]) {
          let val = (ev[1] / 1000) - element[1];
          evConsumptiom.push([ev[0], val])
        }
      });
    });

    this.evMonthlyConsumptionData = evConsumptiom
    this.stscEngine.products.predictedEnergy = this.stscEngine.prediction
    this.calculateCostsRefreshTariffs();
    this.reloadGraphTariffs();
  }

  async calculateCostsRefreshTariffs() {
    await this.stscEngine.products.calcCosts()
    this.stscEngine.sortProductCost()
    this.results = JSON.parse(JSON.stringify(this.stscEngine.products.products));
    this.filterResults = this.results.slice(0, 3);
    this.resultscount = this.results.length
  }

  evOwned(event) {
    this.evChecked = event
    this.sendGAEvent({
      event: 'graphfilters',
      graphFilterItem: 'flexibility',
      graphFilterValue: 'smart',
      ev: event ? 'yes' : 'no'
    })
  }

  changeRating(rating) {
    console.log('changeRating', rating)

    this.productFeature(true, { brand_score: rating });

    this.sendGAEvent({
      event: 'filterresults',
      filterResults: rating,
      filterCategory: 'Contract',
      filterItem: 'Supplier rating',
      status: 'on'
    })
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }

  async productFeature(event, criteria) {
    console.log('event', event)
    if (event) {

      if (this.matchCriteria.filter(x => x.brand_score).length > 0 && criteria.brand_score) {
        let brandRemover = []
        this.matchCriteria.forEach(element => {
          if (!element.brand_score) {
            brandRemover.push(element)
          }
        });
        this.matchCriteria = brandRemover;
      }

      if (criteria.brand_score) {
        switch (criteria.brand_score) { // Sorry
          case 0 || 1:
            this.matchCriteria.push({ brand_score: 1 });
            this.matchCriteria.push({ brand_score: 2 });
            this.matchCriteria.push({ brand_score: 3 });
            this.matchCriteria.push({ brand_score: 4 });
            this.matchCriteria.push({ brand_score: 5 });
            break;
          case 2:
            this.matchCriteria.push({ brand_score: 2 });
            this.matchCriteria.push({ brand_score: 3 });
            this.matchCriteria.push({ brand_score: 4 });
            this.matchCriteria.push({ brand_score: 5 });
            break
          case 3:
            this.matchCriteria.push({ brand_score: 3 });
            this.matchCriteria.push({ brand_score: 4 });
            this.matchCriteria.push({ brand_score: 5 });
            break;
          case 4:
            this.matchCriteria.push({ brand_score: 4 });
            this.matchCriteria.push({ brand_score: 5 });
            break;
          case 5:
            this.matchCriteria.push({ brand_score: 5 });
            break;
        }
      } else {
        this.matchCriteria.push(criteria);
      }

    } else {
      this.matchCriteria.forEach((element, i) => {
        if (Object.keys(element)[0] == Object.keys(criteria)[0]) {
          if (element[Object.keys(element)[0]] == criteria[Object.keys(element)[0]]) {
            this.matchCriteria.splice(i, 1);
          }
        }
      });
    }

    if (this.matchCriteria && this.matchCriteria.length == 0) {
      this.calculateCostsRefreshTariffs();
    }
    else {
      await this.stscEngine.filterTariffs(this.matchCriteria);
      this.stscEngine.sortProductCost();

      this.results = []
      console.log('this.stscEngine.products.filteredTariffs', this.stscEngine.products.filteredTariffs)
      this.stscEngine.products.filteredTariffs.map(tar => {
        let product = this.stscEngine.products.products.filter(prod => prod.fkid == tar.id)
        if (product) {
          this.results.push(product[0])
        }
      })

      this.results.sort((a, b) => a.annualcost - b.annualcost); // Temp solution
      this.filterResults = this.results.slice(0, 3);
    }
    this.resultscount = this.results.length
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  refreshLoadTime(loadtime) {
    console.log('refreshLoadTime')
    this.showResults = false
    this.showFilters = false
    this.showNoData = true;
    this.timeRemaining = this.secondsToHms(loadtime)
    this.sendGAEvent({ event: 'resultsAvailable', viewingResults: false, loadtime: loadtime })

    this.interval = setInterval(() => {
      console.log('refreshLoadTime')
      this.loadTime()
    }, 60000);
  }

  loadTime() {
    this.glowService.getResultLoadTime().subscribe((callback) => {
      this.loadtimeObj = callback
      if (callback && callback.loadtime < 120) {
        this.showResults = true;
        this.showFilters = true;
        this.showNoData = false;
        this.loadData().then(response => {
          console.log('Consumption data loaded', response)
          this.loadResults()
        })
        clearInterval(this.interval);
      } else {
        this.timeRemaining = this.secondsToHms(callback.loadtime)
        this.loadtime = callback.loadtime
      }
      this.dataService.setLoadtime(callback);
    }, (err) => {
      console.warn(err)
    })
  }

  async downloadBinary() {

    let stscServiceURL = "https://beta.smarttariffsmartcomparison.org/consumption.php";
    let token = localStorage.getItem('id_token')

    await this.http.get(stscServiceURL, {
      headers: { 'token': token }, responseType: "arraybuffer"
    }).subscribe(data => {
      var file = new Blob([data], { type: 'application/binary' });
      var fileURL = URL.createObjectURL(file);
      var link = document.createElement('a');
      link.href = fileURL;
      link.download = "consumption-binary.stc";
      link.click();
    })
  }

  downloadCSV() {

    let energy = this.stscEngine.getEnergy();
    let token = localStorage.getItem('id_token')

    energy._token = token;
    energy.load().then(() => {
      return this.csvDownloadService.convertDataToCsvAndDownload(energy.data, 'consumption', 'standardDataArray')
    });
  }

}