import { Injectable } from '@angular/core';
import { tResourceReadings, tIsValid } from './glow.typings';

@Injectable()

export class CsvDownloadService {

    serviceId: string = 'CsvDownloadService';

    constructor() { }

    setDatetimeParams(data: tResourceReadings[]) {

        let csvTimeData = { headers: ['datetime', 'epoch'], data: [] }

        if (data && Array.isArray(data) && data.length >= 1) {

            let dataArrayForTimeParamsExtraction = []

            // Get elements we need from resourceReadings
            data.forEach((dataElement) => {
                if (dataElement.data && dataElement.data.length > 0) {
                    dataArrayForTimeParamsExtraction.push({ name: dataElement.name, data: dataElement.data });
                }
            })

            // Merge resources into one object
            var output = [];
            dataArrayForTimeParamsExtraction.forEach(function (item) {

                var existing = output.filter(function (v, i) {
                    return v.name == item.name;
                });
                if (existing.length) {
                    var existingIndex = output.indexOf(existing[0]);
                    output[existingIndex].data = output[existingIndex].data.concat(item.data);
                } else {
                    if (typeof item.data == 'string')
                        item.data = [item.data];
                    output.push(item);
                }
            });

            let dataElemForTimeParamsExtraction = output[0];

            console.log(this.serviceId + " Selected dataElemForTimeParamsExtraction: " + dataElemForTimeParamsExtraction.name)

            if (dataElemForTimeParamsExtraction && Array.isArray(dataElemForTimeParamsExtraction.data)) {
                csvTimeData.data = dataElemForTimeParamsExtraction.data.map((dp) => { return { 'datetime': new Date(dp[0]).toISOString(), epoch: dp[0] } });
            }

        } else {
            console.warn("Unexpected data inputted")
            console.warn(data)
        }

        return csvTimeData
    }

    convertDataToSingleArrayJSON(resourcesReadings: tResourceReadings[]) {
        let csvData = this.setDatetimeParams(resourcesReadings)
        let i = 0;

        resourcesReadings.forEach((resource) => {
            let name = resource.name
            if (csvData.headers.includes(name) === false) csvData.headers.push(name);

            resource.data.forEach((dp) => {
                csvData.data[i][name] = dp[1]
                i++;
            });
        })

        return csvData
    }

    /**
     * Name of  becomes header and tiemstamps are keys per row
     * 
     * Datetime, timestamp, resource1, resource2
     * Datetime, timestamp, ElecResourceSite1, ElecResourceSite2
     * 
     * @param data 
     */

    convertDataToCsv(resourcesReadings: tResourceReadings[]) {

        console.log("convertDataToCsv")
        let data = this.convertDataToSingleArrayJSON(resourcesReadings)

        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        const header = data.headers;
        console.log(header)

        let csv = data.data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    downloadFile(data: string, filename: string): Promise<tIsValid> {
        let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);

        return new Promise((resolve, reject) => {
            let response = { valid: true }
            resolve(response)
        })
    }

    convertDataToCsvAndDownload(resourcesReadings: any, filename: string, csvType: string): Promise<tIsValid> {

        let csvData;
        switch (csvType) {
            case 'groupConsumptionStatus':
                csvData = this.convertGroupConsumptionStatusToCsv(resourcesReadings);
                break;
            case 'groupConsumptionData':
                csvData = this.convertGroupConsumptionDataToCsv(resourcesReadings);
                break;
            case 'comparisonConsumptionData':
                csvData = this.convertComparisonConsumptionDataToCsv(resourcesReadings);
                break;
            case 'comparisonBySiteData':
                csvData = this.convertComparisonBySiteDataToCsv(resourcesReadings);
                break;
            case 'standardDataArray':
                csvData = this.convertStandardDataArrayToCsv(resourcesReadings);
                break;
            case 'multiCountDataArray':
                csvData = this.convertMultiCountDataArrayToCsv(resourcesReadings);
                break;
            default:
                csvData = this.convertDataToCsv(resourcesReadings);
                break;
        }

        if (csvData) {
            return this.downloadFile(csvData, filename)
        } else {
            console.warn("No csv data to download")
            return new Promise((resolve, reject) => {
                console.warn(this.serviceId + " an error occured when downloading pdf")
                reject({ valid: false })
            })
        }
    }

    convertGroupConsumptionDataToCsv(resourcesReadings: any) {

        console.log("convertGroupConsumptionDataToCsv",resourcesReadings)

        const newData = resourcesReadings.map((response) => ({
            "First Line": response[0],
            "Property Code": response[2],
            "Energy (kWh)": response[1]
        }));

        const header = Object.keys(newData[0])
        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        let csv = newData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    convertGroupConsumptionStatusToCsv(resourcesReadings: any) {

        console.log("convertGroupConsumptionStatusToCsv")

        const newData = resourcesReadings.map(({ flat, prn, heatkwh, tsMeterTT }) => ({
            "First Line": flat,
            "Property code": prn,
            "Last Meter Register (kWh)": heatkwh,
            "Last Updated": tsMeterTT,
        }));

        const header = Object.keys(newData[0])
        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        let csv = newData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    convertComparisonConsumptionDataToCsv(resourcesReadings: any) {

        console.log("convertComparisonConsumptionDataToCsv")

        let csvData = { headers: ['datetime'], data: [] }

        // Create headers
        resourcesReadings.forEach((resource) => {
            let name = resource.displayName
            csvData.headers.push(name)

            resource.data.forEach((dp, ind) => {
                csvData.data[ind] = {}
                csvData.data[ind]['datetime'] = dp[0]
            });
        })

        csvData.headers.map(function (heading) { // Loop through headings

            if (heading != 'datetime') { // ignore datetime heading

                resourcesReadings.forEach((resource) => { // get the resource data for each heading

                    if (resource.displayName == heading) { // make sure the heading matches the resource data

                        csvData.data.map(function (data, index) { // loop through each datetime in data array

                            let newData = resource.data.filter(function (readings) { // find the corresponding data from resource data
                                return readings[0] == data.datetime
                            });

                            // add it to the csv data array
                            if (newData && Array.isArray(newData) && newData.length >= 1) {
                                csvData.data[index][resource.displayName] = newData[0][1]
                            }
                        })
                    }
                })
            }
        });

        // Format datetime
        Object.entries(csvData.data).forEach(([key, value]) => {
            if (value.datetime) {
                value.datetime = new Date(value.datetime).toISOString()
            }
        })

        const header = csvData.headers
        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        let csv = csvData.data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    convertComparisonBySiteDataToCsv(resourcesReadings: any) {

        console.log("convertComparisonConsumptionDataToCsv")

        let csvData = { headers: ['datetime'], data: [] }

        // Create headers
        resourcesReadings.forEach((resource) => {
            let name = resource.label
            csvData.headers.push(name)

            resource.data.forEach((dp, ind) => {
                csvData.data[ind] = {}
                csvData.data[ind]['datetime'] = dp[0]
            });
        })

        csvData.headers.map(function (heading) { // Loop through headings

            if (heading != 'datetime') { // ignore datetime heading

                resourcesReadings.forEach((resource) => { // get the resource data for each heading

                    if (resource.label == heading) { // make sure the heading matches the resource data

                        csvData.data.map(function (data, index) { // loop through each datetime in data array

                            let newData = resource.data.filter(function (readings) { // find the corresponding data from resource data
                                return readings[0] == data.datetime
                            });

                            // add it to the csv data array
                            if (newData && Array.isArray(newData) && newData.length >= 1) {
                                csvData.data[index][resource.label] = newData[0][1]
                            }
                        })
                    }
                })
            }
        });

        const header = csvData.headers
        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        let csv = csvData.data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    convertStandardDataArrayToCsv(data: any) {
        console.log("convertStandardDataArrayToCsv")

        let csvData = { headers: [], data: [] }

        // Create headers
        csvData.headers = Object.keys(data[0])
        data.forEach((dp, ind) => {
            csvData.data[ind] = {}
        })

        // Loop through headings
        for (let i = 0; i < csvData.headers.length; i++) {
            // Push data
            data.forEach((resource, j) => {
                csvData.data[j] = resource
            })
        }

        const header = csvData.headers
        const replacer = (key, value) => value === null ? null : value; // specify how you want to handle null values here
        let csv = csvData.data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        []
        csv.unshift(['Timestamp','Wh'].join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }

    convertMultiCountDataArrayToCsv(resourcesReadings: any) {
        console.log("convertMultiCountDataArrayToCsv")

        let csvData = { headers: Object.values(resourcesReadings[0].headings), data: [], footers: ['', ''] }

        resourcesReadings[0].meterReadCountByMonth.forEach((resource, i) => {
            csvData.data[i] = []
            csvData.data[i].push(resource.prn, resource.meterSerial)
            resource.data.forEach((month) => {
                csvData.data[i].push(month[1])
            });
        })

        resourcesReadings[0].total.forEach((footer) => {
            csvData.footers.push(footer.total)
        })

        const header = csvData.headers
        const footer = csvData.footers
        let csv = csvData.data.map(row => row.join(','));
        csv.unshift(header.join(','));
        csv.push(footer.join(','));
        let csvArray = csv.join('\r\n');
        return csvArray
    }
}