import { Component, Input, OnInit } from '@angular/core';
import { StscEngineService } from '../../../stsc-engine.service';
import { Info } from 'luxon';
import * as Highcharts from 'highcharts';

import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);

@Component({
  selector: 'app-average-daily',
  templateUrl: './average-daily.component.html',
  styleUrls: ['./average-daily.component.scss']
})
export class AverageDailyComponent implements OnInit {

  @Input() selectedGraphFilter: string

  hhData;
  actualHHData
  subtractedHHData
  addedHHData
  yMax = 0
  chart = []
  chartOptions;
  resultsChart: typeof Highcharts = Highcharts;
  updateChartFlag = false;
  showResults = false

  heatMap: typeof Heatmap = Heatmap;
  heatMapOptions;
  updateHeatMapFlag = false;

  constructor(
    private stscService: StscEngineService
  ) {
    this.setGraphOptions()
  }

  ngOnInit(): void {
    this.hhData = this.stscService.useByDay();

    for (let i = 0; i < 7; i++) {
      if (this.yMax < Math.max(...this.hhData[i])) {
        this.yMax = Math.max(...this.hhData[i]);
      }
    }

    this.redrawGraphs();
    if (this.selectedGraphFilter) {
      this.toggleView()
    }
  }

  ngOnChanges() {
    if (this.selectedGraphFilter) {
      this.toggleView()
    }
  }

  toggleView() {
    switch (this.selectedGraphFilter) {
      case 'smart':
        this.scenarioEV()
        break;
      case 'none':
        this.scenarioClear()
        break;
      case 'low':
        this.scenarioFlex(1)
        break;
      case 'medium':
        this.scenarioFlex(2)
        break;
      case 'high':
        this.scenarioFlex(3)
        break;
    }
  }

  scenarioClear(): void {
    this.stscService.clearPrediction();
    this.hhData = this.stscService.useByDay();
    this.actualHHData = []
    this.subtractedHHData = []
    this.redrawGraphs();
  }

  scenarioFlex(difficulty): void {
    let percent = difficulty / 10;
    this.stscService.timeShiftEnergy(percent);
    this.actualHHData = this.stscService.useByDay();
    this.hhData = this.stscService.predictedUseByDay();

    let subtracted = [[], [], [], [], [], [], []]
    let added = [[], [], [], [], [], [], []]
    this.hhData.forEach((element, j) => {
      element.forEach((item, index) => {
        let val = item - this.actualHHData[j][index]
        if (val < 0) {
          subtracted[j].push(Math.abs(val));
          added[j].push(0);
        } else {
          added[j].push(val);
          subtracted[j].push(0);
        }
      })
    });

    this.subtractedHHData = subtracted
    this.addedHHData = added

    // Replace predicted values with actual values = 
    this.hhData.forEach((element, j) => {
      element.forEach((item, index) => {
        if (item > this.actualHHData[j][index]) {
          element[index] = this.actualHHData[j][index]
        }
      })
    })

    this.redrawGraphs();
  }

  scenarioEV(): void {
    this.stscService.addEV();
    this.hhData = this.stscService.predictedUseByDay();
    this.actualHHData = []
    this.subtractedHHData = []
    this.addedHHData = []
    this.redrawGraphs();
  }


  private redrawGraphs(): void {
    this.chart = []
    this.showResults = false
    this.setGraphOptions()

    setTimeout(() => {
      for (let i = 0; i < 7; i++) {
        this.chart.push({
          ...this.chartOptions,
          title: { text: Info.weekdays()[i], align: 'left', margin: 0, y: 20 },
          series: []
        })

        if (this.subtractedHHData.length > 0) {
          this.chart[i].series.push({ name: null, type: 'column', data: this.subtractedHHData[i], groupPadding: 0, pointPadding: 0, pointWidth: 10, color: '#cad1db' })
          this.chart[i].series.push({ name: null, type: 'column', data: this.addedHHData[i], groupPadding: 0, pointPadding: 0, pointWidth: 10, color: '#3366FF' })
        }

        this.chart[i].series.push({ name: null, type: 'column', data: this.hhData[i], groupPadding: 0, pointPadding: 0, pointWidth: 10 })
      }
      this.showResults = true
    }, 100);
  }

  setGraphOptions() {

    this.chartOptions = {
      chart: {
        marginTop: 10,
        marginBottom: 50,
        reflow: true,
      },
      title: {
        // text: Info.weekdays()[i],
        align: 'left',
        margin: 0,
        y: 20
      },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
        }
      },
      xAxis: {
        type: "category",
        labels: {
          format: "{value}"
        },
        categories: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
          '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
          '14:00', '14:30', '15:00', '15:30', '16:00', '016:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
          '22:00', '22:30', '23:00', '23:30'],
        tickInterval: 6
      },
      yAxis: {
        title: {
          text: null
        },
        // max: this.yMax,
        visible: false,
        tickAmount: 2,
        labels: {
          showFirstLabel: false,
          showLastLabel: false,
        }
      },
      tooltip: { pointFormat: '<b>{point.y} Wh</b><br/>' },

    }
  }

}