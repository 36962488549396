<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Your rights with your data</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>Hildebrand Technology</h6>

        <p>FAST is powered by Hildebrand Technology; Hildebrand are registered with the
            Information Commissioner’s Office (Organisation Reference Z3161543) and make every effort to be fully
            compliant with the <a href="https://ico.org.uk/your-data-matters" target="_blank">obligations the ICO stipulates</a> on
            behalf of consumers.
        </p>

        <p>Your rights include:</p>

        <ul>
            <li>To be informed if your personal data is being used</li>
            <li>To get copies of your data</li>
            <li>To get your data corrected</li>
            <li>To get your data deleted</li>
            <li>To limit how we use your data</li>
            <li>To data portability</li>
            <li>To object to the use of your data</li>
            <li>To raise a concern</li>
        </ul>

        <p>You can <a href="/stop-data-collection">tell us to stop collecting your smart meter data</a> and to ask us to
            delete that data at any time.</p>

        <p>Please send an email to <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> at any time to
            exercise your rights, or bring any concerns you may have to our attention.</p>

    </div>
</div>