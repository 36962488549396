import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  private hostUrl: string;

  constructor(private http: HttpClient) {

    this.hostUrl = 'http://localhost:1337';

  }

  getArticles() {
    return this.http.get(this.hostUrl + "/articles");
  }

  getArticle(id) {
    return this.http.get(this.hostUrl + "/articles/" + id);
  }

}
