import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'stsc-input-rating',
  templateUrl: './stsc-input-rating.component.html',
  styleUrls: ['./stsc-input-rating.component.scss']
})
export class StscInputRatingComponent implements OnInit {

  @Output() changeRating = new EventEmitter<any>();
  value: number = 1;

  constructor() { }

  ngOnInit(): void { }

  onClick(star) {
    this.value = star;
    this.changeRating.emit(this.value);
  }
}