import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unbiased',
  templateUrl: './unbiased.component.html',
  styleUrls: ['./unbiased.component.scss']
})

export class UnbiasedComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }
}