import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'stsc-trust',
  templateUrl: './stsc-trust.component.html',
  styleUrls: ['./stsc-trust.component.scss']
})
export class StscTrustComponent implements OnInit {

  @Input() value: number = 0;
  @Input() rowData//: number = 0;

  displayValue: number
  constructor() { }

  ngOnInit(): void {
    if (this.rowData && this.rowData.tariff && this.rowData.tariff.brand_score) {
      this.displayValue = this.rowData.tariff.brand_score
    } else {
      this.displayValue = this.value
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.rowData && this.rowData.tariff && this.rowData.tariff.brand_score) {
      this.displayValue = this.rowData.tariff.brand_score
    } else {
      this.displayValue = this.value
    }
  }

}
