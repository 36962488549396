<nb-accordion multi="true">
    <nb-accordion-item expanded="true">
        <nb-accordion-item-header>Contract</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div class="row">
                <div class="col-3"><span class="text-hint">Tariff Type</span></div>

                <div class="col-9">
                    <nb-checkbox (checkedChange)="productFeature($event, { 'type': 'flat' })" status="basic">Flat</nb-checkbox>
                    &nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'type': 'tou' })" status="basic">Time of Use
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'type': 'dynamic' })" status="basic">Dynamic</nb-checkbox>
                    &nbsp;
                </div>

            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Green</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="productFeature($event, { 'is_green': true })" status="basic">100% renewable energy
                    </nb-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Contract length</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="productFeature($event, { 'term': null })" status="basic">None</nb-checkbox>
                    &nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'term': 12 })" status="basic">12 months
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'term': 99 })" status="basic">24+ months
                    </nb-checkbox>&nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Exit fee</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(10)" status="basic">No exit fee
                    </nb-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Supplier rating</span></div>
                <div class="col-9">
                    <!-- <stsc-input-rating></stsc-input-rating> -->
                </div>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item expanded="true">
        <nb-accordion-item-header>Usage</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div class="row">
                <div class="col-3"><span class="text-hint">Low Carbon Technology</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(11)" status="basic">Solar</nb-checkbox>
                    &nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(12)" status="basic">EV</nb-checkbox>
                    &nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(13)" status="basic">Heat Pump
                    </nb-checkbox>&nbsp;
                </div>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item expanded="true">
        <nb-accordion-item-header>Smart</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div class="row">
                <div class="col-3"><span class="text-hint">Smart meter</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(14)" status="basic">Smart electric
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(15)" status="basic">Smart gas
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'is_prepay': true })" status="basic">Smart prepay
                    </nb-checkbox>&nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Traditional meter</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(17)" status="basic">Traditional electric
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(18)" status="basic">Economy 7
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(19)" status="basic">Economy 10
                    </nb-checkbox>&nbsp;
                </div>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item expanded="true">
        <nb-accordion-item-header>Price</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div class="row">
                <div class="col-3"><span class="text-hint">Price guarantee for contract</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="productFeature($event, { 'is_variable': false })" status="basic">Fixed rate
                    </nb-checkbox>&nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Payment types</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(20)" status="basic">Direct debit
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="toggleFeature(21)" status="basic">On demand
                    </nb-checkbox>&nbsp;
                    <nb-checkbox (checkedChange)="productFeature($event, { 'is_prepay': true })" status="basic">Prepay</nb-checkbox>
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3"><span class="text-hint">Price</span></div>
                <div class="col-9">
                    <nb-checkbox (checkedChange)="toggleFeature(23)" status="basic">Less than my current
                        cost</nb-checkbox>&nbsp;
                </div>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>

<div>showing {{ products.filteredProducts.length }} of {{ products.products.length }} products</div>
<nb-card size="small" *ngFor="let product of products.filteredProducts">
    <nb-card-body>
            <span class="results_display_name">{{product.displayname}}</span>
            <div class="col" style="text-align: right;">
                <nb-icon (click)="like(product)" *ngIf="!product.like"
                    class="likeTariffIcon" icon="heart-outline" status="basic"></nb-icon>
                <nb-icon (click)="like(product)" *ngIf="product.like"
                    class="unlikeTariffIcon" icon="heart" status="danger" nbTooltip="Liked">
                </nb-icon>
            </div>
</nb-card-body>