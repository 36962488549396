import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'stsc-features',
  templateUrl: './stsc-features.component.html',
  styleUrls: ['./stsc-features.component.scss']
})
export class StscFeaturesComponent implements OnInit {

  @Input() public isFlex: boolean = false;
  @Input() public isSolar: boolean = false;
  @Input() public isEV: boolean = false;

  public flexibility_label;

  constructor() { }

  ngOnInit(): void {
    if(this.isFlex) {
      this.flexibility_label = "Flexible";
    } else {
      this.flexibility_label = "Variable";
    }
  }

}
