import { Component } from "@angular/core";
import { Router } from '@angular/router';

import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

// import { UIConfigService } from 'app/glow-angular-ionic/services/ui-configuration.service';
// import { tLoadingElementWithSuccess } from "app/glow-angular-ionic/components/component.typings";
// import { UserGroupSelectorService } from "app/services/usergroup-selector.service";
import { GlowService } from "src/app/glow.service";
import { tLoginInfo, tUserOrganization } from "src/app/glow.typings";
import { FormValidator } from "src/app/shared/form-validators";

@Component({
    styleUrls: ['./reset-password.component.scss', '../login/login.component.scss', '../../app.component.scss'],
    templateUrl: './reset-password.component.html',
})

export class ResetPasswordComponent {
    formInfo: FormGroup;
    // loadObj: tLoadingElementWithSuccess;
    loadObj: any

    constructor(
        private formBuilder: FormBuilder,
        private authService: GlowService,
        private router: Router,
        // private uiConfigService: UIConfigService,
        // private userGroupSelectorService: UserGroupSelectorService
    ) {
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false)
        this.formInfo = this.formBuilder.group({
            username: ['', Validators.required],
            token: ['', Validators.required],
            password: ['', [Validators.required, FormValidator.isPasswordStrong]],
            confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]]
        });
    }

    submitForm() {
        console.log("Password Reset attempt")
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true)
        const data = { username: this.formInfo.value.username, newPassword: this.formInfo.value.password, token: this.formInfo.value.token };
        this.authService.resetPassword(data)
            .subscribe((response) => {
                if (response.valid === true) {
                    this.doLogin()
                } else {
                    this.loadObj = {
                        // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                        loadError: true,
                        errorString: 'ERROR'
                    }
                }
            }, (err) => {
                console.warn(err)
                let errMsg;
                try {
                    const errJson = err.json()
                    if (errJson.error) {
                        errMsg = errJson.error
                    } else if (errJson.valid === false) {
                        errMsg = 'Invalid username or password'
                    }
                } catch (e) {
                    console.warn(e)
                }
                this.loadObj = {
                    // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                    loadError: true,
                    errorString: errMsg || 'ERROR'
                }
            });
    }

    doLogin() {
        console.log("Login attempt")
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true)
        const loginDetails: tLoginInfo = { username: this.formInfo.value.username, password: this.formInfo.value.password };
        this.authService.login(loginDetails).subscribe(authResponse => {
            // authentication success
            if (authResponse === true) {
                console.log('successfully logged in')
                this.loadObj = { loaded: true, loadedMessage: 'Success!' }
                // this.findUserOrganizations()
            } else {
                console.warn("Unable to authenticate with new password")
                this.loadObj = {
                    // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                    loadError: true,
                    errorString: 'ERROR'
                }
            }
        }, (err) => {
            console.warn(err)
            this.loadObj = {
                // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                loadError: true,
                errorString: 'ERROR'
            }
        });
    }

    // findUserOrganizations() {
    //     this.authService
    //         .getUserOrganizationsOfUser()
    //         .subscribe(userOrganizations => {
    //             this.loadObj = {
    //                 ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
    //                 loaded: true,
    //                 loadedMessage: 'Success'
    //             }
    //             this.goToNextPage(userOrganizations)
    //         }, error => {
    //             console.warn(error)
    //             console.log('User Organizations loading error is : ' + error);
    //         });
    // }

    // goToNextPage(userOrganizations: tUserOrganization[]) {
    //     if (userOrganizations && Array.isArray(userOrganizations) && userOrganizations.length > 1) {
    //         this.router.navigateByUrl('/pages/select-site');

    //     } else if (userOrganizations.length === 1) {
    //         this.userGroupSelectorService.setUserOrganization(userOrganizations[0])
    //         this.router.navigateByUrl('/pages/dashboard');

    //     } else {
    //         console.warn("No user Organizations found")
    //         this.router.navigateByUrl('/pages/user-management');
    //     }
    // }
}