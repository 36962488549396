import { Component, OnInit } from '@angular/core';
import { StscEngineService } from '../../stsc-engine.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products;
  filteredProducts;
  matchCriteria = [];

  constructor(public productsService: StscEngineService) { }

  ngOnInit(): void {
    this.products = this.productsService.products;
    this.filteredProducts = this.productsService.products.match(this.matchCriteria);
  }

  like(product) : void {
    this.productsService.likeProduct(product);
  }

  toggleFeature(field) {
    console.log(field); 
  }

  productFeature(event, criteria) {
    if(event) { 
      this.matchCriteria.push(criteria);
    } else {
      this.matchCriteria.forEach((element, i) => {
        if(Object.keys(element)[0] == Object.keys(criteria)[0]) {
          if(element[Object.keys(element)[0]] == criteria[Object.keys(element)[0]]) {
            this.matchCriteria.splice(i, 1);
          }
        }
      });
    }
    this.filteredProducts = this.productsService.products.match(this.matchCriteria);
  }

}
