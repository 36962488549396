import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-tou-tariff-details',
  templateUrl: './tou-tariff-details.component.html',
  styleUrls: ['./tou-tariff-details.component.scss']
})

export class TOUTariffDetailsComponent implements OnInit {

  @Input() data: any;
  touData: any

  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
    console.log('this.data', this.data)
    if (this.data && this.data.tariff && this.data.tariff._tou) {
      this.touData = this.data.tariff._tou
      console.log('this.touData', this.touData)
    }
  }

  ngOnChanges() {
    console.log('this.data', this.data)
    if (this.data && this.data.tariff && this.data.tariff._tou) {
      this.touData = this.data.tariff._tou
      console.log('this.touData', this.touData)
    }
  }

  ngOnDestroy() { }

  close() {
    this.dialogRef.close();
  }
}