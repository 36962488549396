import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-smart-tariff',
  templateUrl: './smart-tariff.component.html',
  styleUrls: ['./smart-tariff.component.scss']
})

export class SmartTariffComponent implements OnInit {

  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit() { }

  ngOnDestroy() { }

  close() {
    this.dialogRef.close();
  }
}