<nb-card style="width: 60%; margin: 0 auto; height: 60%; position: relative; top: 200px;">
    <nb-card-header style="background-color: #F7F9FC;">FAST Terms & Conditions of Use</nb-card-header>
    <nb-card-body>
        <p>Last updated: 4 February 2021</p>
        <p>FAST (STSC) is provided by Hildebrand Technology Limited, Company number 05577050.
            To contact Hildebrand, please send an email to <a
                href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>
        </p>
        <p>By registering with the STSC web site, you agree you occupy the property associated to your post code and
            Energy Display, have read the terms and conditions and are opting in to Hildebrand Technology Limited:
        </p>
        <ul>
            <li>collecting consumption and tariff data</li>
            <li>storing consumption and tariff data</li>
            <li>collecting meter details</li>
        </ul>
        <p>In accordance with the provision of the STSC App and Glow service.</p>
        <h6>1 Introduction</h6>
        <p>The STSC service is provided for your personal use subject to the following Terms of Use. By registering with
            the service, you agree that you have read and accepted these terms. These Terms of Use reflect widely used
            common sense practices in using similar internet services; users must observe these rules governing their
            use of the STSC service.
        </p>
        <h6>2 Information about STSC</h6>
        <p>STSC is an application designed to help people understand and manage their home energy consumption and
            identify possible smart tariffs they could benefit from. STSC is wholly owned and operated by Hildebrand
            Technology Ltd (“Hildebrand”). Hildebrand Technology is registered in England with a registered trading
            address of 56 Conduit Street, London W1S 2YZ. Hildebrand Technology’s Company Number is 05577050 and the
            VAT number is 872 434 711. Glow is the technology platform that powers applications including STSC. Glow
            is wholly owned and operated by Hildebrand Technology Ltd. Other applications that you might be able to
            use if you have compatible hardware are found at <a href="https://glowmarkt.com"
                target="_blank">https://glowmarkt.com</a>, the Glow app store.
        </p>
        <h6>3 Registering with STSC</h6>
        <h6>3.1 First time registration</h6>
        <p>Registration involves providing us with your email address and setting a password. To view your own
            energy consumption data in the STSC application you need to have provided us with your post code and an
            EUI/GUID from the Energy Display associated to your smart meter; that smart meter needs to be in the
            DCC Inventory (a SMETS2 meter, or SMETS1 meter that has been migrated into the DCC Infrastructure.
        </p>
        <h6>3.2 Registered STSC account</h6>
        <p>With a registered STSC account, you can use the STSC web site. To view your own energy consumption data
            in the STSC application, you need to login with your username name and password.
        </p>
        <h6>4 Your account</h6>
        <p>You are responsible for maintaining the confidentiality of your email address and password and must take
            all reasonable steps to keep it confidential. If your security is breached advise us immediately (email
            <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>) and we will cancel or reset the
            security credentials on your account.
        </p>
        <p>You can change your password at any time by logging in to the application, and going
            to the Login page and clicking on Forgot Password. To change your email address, please
            contact <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> with your original email
            address and the new one you wish to use.
        </p>
        <p>You may cancel your account to STSC and/or the Glow platform through the STSC App or by contacting us on
            email at <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> or by post at our registered
            address 56 Conduit Street, London W1S
            3YZ. It may take up to one working week after the receipt of your request to process your cancellation.
            We will delete your data and remove you from the system.
        </p>
        <h6>5 Your data</h6>
        <p>By using STSC you consent to the collection and use of the information by Hildebrand. We may collect and
            store any of the following:
        </p>
        <ul>
            <li>An encrypted copy of your STSC username and password.</li>
            <li>Information about how you interact with STSC.</li>
            <li>The unique identifier for your electricity or gas supply known as your MPAN for electricity and MPRN
                for gas.
            </li>
        </ul>
        <h6>6 Service Description</h6>
        <p>The sole element to the STSC service, is the web site. To use the STSC application you need a user account
            and password. Unless we state otherwise, any new services added to STSC will be subject to these Terms of
            Use.
        </p>
        <h6>6.1 Electricity consumption and cost monitoring</h6>
        <p>Customers with smart meters that are connected to the Data Communications Company (DCC) do not have to have
            a Glow hub for Hildebrand to capture their half hourly consumption data and tariffs via the DCC. They do
            have to have been verified as having the right to access their data.
        </p>
        <p>The cost shown for your energy may also differ to what you are billed depending on discounts, surcharges or
            other adjustments your energy supplier may make in addition to the cost of energy.
        </p>
        <p>Monitoring your electricity consumption is done through the STSC application or other compatible
            applications found in the GlowMarkt app store at <a href="https://glowmarkt.com"
                target="_blank">https://glowmarkt.com</a>. The Glow hub must be on power and
            connected to the Internet in order for the real-time service to function.
        </p>
        <h6>6.2 Optional gas consumption and cost monitoring</h6>
        <p>Customers with gas smart meters that are connected to the Data Communications Company (DCC) do not have to
            have a Glow hub for Hildebrand to capture their half hourly consumption data and tariffs via the DCC. They
            do have to have been verified as having the right to access their data.
        </p>
        <p>Monitoring your gas consumption is done through the STSC application or other compatible applications found
            in the GlowMarkt app store at <a href="https://glowmarkt.com" target="_blank">https://glowmarkt.com</a>. If
            there is a Glow hub in use, it must be on power and
            connected to the Internet in order for the service to function.
        </p>
        <p>The cost shown for your energy may also differ to what you are billed depending on discounts, surcharges or
            other adjustments your energy supplier may make in addition to the cost of energy.
        </p>
        <h6>6.3 Consumption, tariff and configuration off your smart meter</h6>
        <p>Where data is sourced directly from the smart meter without a Glow hub, data is being accessed through the
            industry network (the DCC) to provide consumption and tariff data to help you manage your energy. To ensure
            your privacy we may access the technical configuration of your smart meter and what devices are connected to
            your smart meter.
        </p>
        <h6>6.4 Glow compatible hardware</h6>
        <p>Variously known as the Glow hub, the GlowStick, the Glow CAD and the Glow IHD/CAD are a range of Glow
            compatible devices which capture energy consumption data from either a smart meter or clamp(s) and a
            transmitter. In this document, the reference to all of the above is to the Glow hub.
        </p>
        <h6>7 Security</h6>
        <p>The information that STSC collects on your energy consumption will be stored locally in your browser. The
            consumption and tariff information that is collected via either the Glow hub or the DCC will be transmitted
            to Hildebrand’s servers.
        </p>
        <p>Hildebrand use a range of tools to protect your information against unauthorised access and disclosure;
            Hildebrand are ISO 27001 certified. Hildebrand have put appropriate technical and procedural measures in
            place to protect the security of your data however we do not guarantee that your personal information or
            private communications will always remain private when using this App.
        </p>
        <h6>8 Service Availability</h6>
        <p>Our goal is to have the service available for 99% of the time but we cannot be held liable for any service
            outages. Planned outages will be notified to users via email, app notices and the GlowMarkt web site.
        </p>
        <h6>9 Changes to STSC and Glow Services</h6>
        <p>The STSC and Glow services are constantly evolving; we reserve the right to implement further practices and
            limits pertaining to the use of the service over time. This includes modifying or discontinuing, temporarily
            or permanently, the service (or any part thereof) with or without notice. You agree that we shall not be
            liable to you or to any third party for any modification, suspension or discontinuance of the service.
        </p>
        <h6>10 Privacy Policy</h6>
        <p>Glowmarkt and the parent company Hildebrand are committed to protecting your privacy within the constraints
            of the law in force at the time of use. Please see our separate Privacy Policy statement. Note: All company
            communications from us will be sent to you from the address <a
                href="mailto:admin@glowmarkt.com">admin@glowmarkt.com</a> or as a message within STSC.
            Support communications from us will come from the address <a
                href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> or
            <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>. We will never ask for your password
            via email or telephone. Please note that our
            Privacy Policy does not form part of your contract with us and that Hildebrand Technology Limited is the
            data controller of your personal data. You can contact Hildebrand Technology Limited’s data protection
            officer by writing to The Data Protection Officer, Hildebrand Technology Limited, 16 Mortimer Street,
            London, W1T 3JL.
        </p>
        <h6>11 International Legal Compliance</h6>
        <p>As the internet transcends national boundaries, you agree to comply with your local laws and policies in
            force regarding online conduct and acceptable content. The STSC service is designed for UK residents only.
        </p>
        <h6>12 Acceptable Use</h6>
        <p>The core tenet of the service is that you must not interfere with the legitimate use by others of the
            Internet.
        </p>
        <h6>13.1 User conduct</h6>
        Any content you post to us must comply with commonly acceptable standards of behaviour. You must not be
        libellous, defamatory or otherwise illegal in what you post. Posted content which is unacceptable includes
        comment which is offensive, aggravates and/or objectionable to others; racist and/or ethnically
        objectionable; obscene; libellous; harmful; hateful; harasses; threatens; defames; abusive; vulgar; incites
        violence. You must respect third party rights and not violate privacy, trademark or copyright. We reserve
        the right to remove such content and terminate the account of users who violate these standards.
        <h6>13.2 User disputes</h6>
        <p>In the unlikely event of users getting into disputes between themselves we expect them to resolve them. If
            we find that such dispute is being conducted in a manner which is detrimental to the best interests of other
            users of the service, we reserve the right to intervene and act appropriately.
        </p>
        <h6>13.3 Use of STSC and Glow Service</h6>
        <p>STSC and Glow may only be used by you for lawful purposes. You are responsible for your STSC and Glow
            account, the data within it and keeping your password confidential. If you realise that someone else has
            been using your account (without your permission) please email us immediately at <a
                href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>.
            You are allowed to use the STSC and Glow service for personal use only. Commercial use of the service can
            result in termination without warning. If you wish to discuss commercial use of the service, please contact
            us at <a href="mailto:admin@glowmarkt.com">admin@glowmarkt.com</a>. If you wish to cancel your access your
            STSC or Glow account at any time, please
            contact us at <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>.
        </p>
        <h6>14 New forms of Internet Abuse</h6>
        <p>New forms of internet abuse are likely to be found that are not covered by the categories defined in this
            Terms of Use. When detected in a STSC or Glow account, we will ask you to stop, and normally provide you
            with 14 days to comply.
        </p>
        <h6>15 Content Ownership</h6>
        <p>Users own their own electricity (and gas if available) data. We will not use energy consumption data for any
            purpose other than for your own use, although we may collate members’ data anonymously to support
            comparison, measurement across groups, on-going analysis, etc. You represent and warrant that with regard to
            any posts you make to the service that you own or otherwise control all of the rights to the content you
            post and that it is accurate and not in breach of our policies or guidelines. You agree to indemnify us for
            any claims brought by a third party against us in connection with any breach of these warranties.
        </p>
        <h6>16 Investigation & Complaint Process</h6>
        <p>If you have a complaint about material posted within STSC, Glow or GlowMarkt, please send an email to
            <a href="mailto:abuse@glowmarkt.com">abuse@glowmarkt.com</a>. If you have a complaint about any aspect of
            the the service, please send an email to
            <a href="mailto:complaints@glowmarkt.com">complaints@glowmarkt.com</a>. We cannot guarantee your issue will
            be dealt with promptly if any other mechanism
            is used.
        </p>
        <p>We reserve the right to investigate suspected violations of these Terms of Use which may result in an
            investigation by any external bodies such as the Police. This could involve gathering data from members, the
            our facilities, other service providers and the complainant. If such an investigation is required and while
            it is under investigation, we are within our rights to suspend the suspect account and either temporarily or
            permanently remove the material involved from the our service.
        </p>
        <p>We may be bound by current or future legal statutes, such as the Regulatory of Investigatory Powers Act 2000
            to access, monitor, store, take copies of, or otherwise deal with the service users’ stored data. Without
            limitation, you expressly authorise us to use personal data and other account information in connection with
            any such investigation, including disclosure to any third party authority that is considered to possess a
            legitimate interest in any such investigation or its outcome.
        </p>
        <h6>17 Termination</h6>
        <h6>17.1 STSC or Glow Termination</h6>
        <p>We reserve the right to terminate or restrict your account for any reason, including but not restricted to
            violation of any of the Terms of Use stated in this document. By accepting these Terms of Use, you agree
            that termination may occur without prior notice.
        </p>
        <h6>17.2 Termination due to Non-Compliant Activity</h6>
        <p>Where serious transgressions or illegal activities are found occurring within the service, we are within our
            rights to inform any relevant authority, including the police. We are within our rights to make any public
            announcement to say action has been taken, including identification details.
        </p>
        <p>Where you are found to be at fault, you will be liable for all costs and damages incurred by us in the
            provision of this service and you will have no compensation in respect of withdrawing your service and/or
            closing your account.
        </p>
        <h6>17.3 Termination due to Inactivity</h6>
        <p>Where an account has not been used for more than 18 months, we reserve the right to delete the account and
            any data associated with the account.
        </p>
        <h6>18 Proprietary Rights</h6>
        <p>You acknowledge and agree that the the STSC and Glow services and any necessary software used in connection
            with the services ('Software') contain proprietary and confidential information that is protected by
            applicable intellectual property and other laws. No content from the site (other than your own electricity
            consumption data or posts to discussion groups) can be copied, captured or quoted in any manner without our
            express written permission. Please contact us at <a
                href="mailto:admin@glowmarkt.com">admin@glowmarkt.com</a> if you wish to make such a request.
            Decompilation, reverse engineering or re-engineering of any nature, or attempting to do so is strictly
            forbidden.
        </p>
        <h6>19 Resale of the Service</h6>
        <p>You expressly agree not to sell, resell, copy, duplicate, reproduce or exploit any part of the STSC or Glow
            service, application or documentation. Commercial use of the service can result in termination without
            warning. If you wish to discuss commercial use of the service, please contact us at <a
                href="mailto:admin@glowmarkt.com">admin@glowmarkt.com</a>.
        </p>
        <h6>20 Changes to the Terms of Use</h6>
        <p>We reserve the right to modify these Terms of Use from time to time without notice as the service continues
            to evolve; we ask you to review them when new changes are issued. Significant changes will be alerted to you
            via the web site. The date of the latest revision will be published at the top of this page.
        </p>
        <h6>21 Assignment</h6>
        <p>We reserve the right to assign this contract at any time whether in whole or in part. You do not have this
            right but can cancel your contract at any time by contacting us at <a
                href="mailto:admin@glowmarkt.com">admin@glowmarkt.com</a>.
        </p>
        <h6>22 Jurisdiction</h6>
        <p>These Terms of Use are governed by English law and under the jurisdiction of English courts. If any of these
            Terms of Use are determined to be invalid or unenforceable pursuant to applicable law including, but not
            limited to, the Limitation of Liability and the Disclaimer of Warranties clauses at the end of this
            document, then the invalid or unenforceable provision will be deemed as having been superseded by a valid,
            enforceable provision which most closely matches the intent of the original provision and the remainder of
            the Terms of Use shall continue in effect.
        </p>
        <h6>23 Indemnity</h6>
        <p>You agree to indemnify and hold Hildebrand Technology Limited, and its subsidiaries, affiliates, officers,
            agents, co-branders or other partners, and employees, harmless from any claim or demand, including
            reasonable lawyers’ fees, made by any third party due to or arising out of your content, your use of the
            STSC or Glow service, your connection to the service, your violation of the Terms of Use, or your violation
            of any rights of another.
        </p>
        <h6>DISCLAIMER OF WARRANTIES</h6>
        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE STSC AND GLOW SERVICE, SOFTWARE, DOCUMENTATION AND ANY
            ASSOCIATED MATERIALS ARE PROVIDED ON AN ‘AS IS’ BASIS WITH NO WARRANTIES WHATSOEVER. HILDEBRAND TECHNOLOGY
            LIMITED AND THEIR SUPPLIERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY
            LAW WHETHER EXPRESS, IMPLIED OR STATUTORY WARRANTIES, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF (A)
            MERCHANTABILITY, (B) FIT FOR A PARTICULAR PURPOSE, (C) NON-INFRINGEMENT, (D) RELIABILITY, (E) INTERRUPTION
            OF SERVICE, (F) TIMELINESS, (G) PERFORMANCE OR (H) ERROR FREE. HILDEBRAND TECHNOLOGY LIMITED DISCLAIM ANY
            WARRANTIES REGARDING (A) THE SERVICE MEETING YOUR REQUIREMENTS, (B) ANY INFORMATION OR ADVICE OBTAINED
            THROUGH THE SERVICE, (C) QUALITY OF ANY GOODS, SERVICES, INFORMATION OR OTHER MATERIALS RECEIVED OR
            PURCHASED THROUGH THE SERVICE, (D) ANY ERRORS WILL BE CORRECTED. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL
            DOWNLOADED OR OBTAINED THROUGH THE USE OF THE STSC OR GLOW SERVICE IS AT YOUR OWN DISCRETION AND RISK AND
            THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM
            THE DOWNLOAD OF SUCH MATERIAL OR DATA.
        </p>
        <h6>LIMITATION OF LIABILITY</h6>
        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT HILDEBRAND TECHNOLOGY WILL IN NO WAY BE LIABLE FOR ANY DAMAGES
            WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, PUNITIVE,
            EXEMPLARY, OR SPECIAL DAMAGES, OR DAMAGES INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA, LOSS OF USE,
            GOODWILL OR ANY OTHER INTANGIBLES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES)
            RESULTING FROM OR ARISING OUT OF (A) PROCUREMENT COST OF SUBSTITUTE GOODS AND/OR SERVICES, (B) INABILITY OR
            USE OF THE SERVICE, (C) UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, (D) UNAUTHORISED
            USE, (E) ANY PERFORMANCE OR NON-PERFORMANCE ISSUE RELATING TO THE SERVICE AND (F) ANY OTHER MATTER RELATING
            TO THE SERVICE.
        </p>
    </nb-card-body>
    <nb-card-footer style="background-color: #F7F9FC;">
        <button (click)="accept()" nbButton status="primary">{{"ACCEPT" | translate}}</button>
        <button (click)="dismiss()" nbButton style="float: right;">{{"DECLINE" | translate}}</button>
    </nb-card-footer>
</nb-card>