import { Component, OnInit } from '@angular/core';
import { GlowService } from '../../glow.service';
import { StscEngineService } from '../../stsc-engine.service';
import { Info } from 'luxon';
import * as Highcharts from 'highcharts';

import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {

  hhData;
  btnEV = false;

  chartOptions = [];
  resultsChart: typeof Highcharts = Highcharts;
  updateChartFlag = false;

  heatMap: typeof Heatmap = Heatmap;
  heatMapOptions;
  updateHeatMapFlag = false;

  constructor(private glowService: GlowService, private stscService: StscEngineService) { }

  ngOnInit(): void {
    this.hhData = this.stscService.useByDay();
    this.redrawGraphs();
  }

  scenarioClear() : void {
    this.hhData = this.stscService.useByDay();
    this.redrawGraphs();
  }

  scenarioFlex(difficulty) : void {
    let percent = difficulty / 10;
    this.stscService.timeShiftEnergy(percent);
    this.hhData = this.stscService.predictedUseByDay();
    this.redrawGraphs();
  }

  scenarioEV(): void {
      this.stscService.addEV();
      this.hhData = this.stscService.predictedUseByDay();
      this.redrawGraphs();
  }




  private redrawGraphs() : void {
    let showXAxis = false;
    let yMax = 0;

    for(let i=0; i < 7; i++) {
      if(yMax < Math.max(...this.hhData[i])) {
        yMax = Math.max(...this.hhData[i]);
      }
    }

    for(let i=0; i < 7; i++) {
      
      if(i == 6) showXAxis = false;

      this.chartOptions[i] = {
        chart: {
          marginLeft: 40, // Keep all charts left aligned
          spacingTop: 0,
          spacingBottom: 0
        },
        title: { 
          text: Info.weekdays()[i],
          align: 'left',
          margin: 0,
          x: 30,
          y: 50 
        },
        legend: { enabled: false },
        credits: { enabled: false },
        xAxis: {
          categories: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', 
          '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
          '14:00', '14:30', '15:00', '15:30', '16:00', '016:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
          '22:00', '22:30', '23:00', '23:30'],
          visible: showXAxis,
          labels: {
          }
        },
        yAxis: { // Primary yAxis
          title: {
            text: null
          },
          max: yMax,
          visible: false,
          tickAmount: 2,
          labels: {
            showFirstLabel: false,
            showLastLabel: false,
          }
        },
        tooltip: { pointFormat: '<b>{point.y} Wh</b><br/>' },
        series: [ { name: null, type: 'column', data: this.hhData[i], groupPadding: 0, pointPadding: 0, pointWidth: 10   } ]
      }
      
    }
    this.updateChartFlag = true;
   
  }


}
