
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GlowService } from 'src/app/glow.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent implements OnInit {

  form: FormGroup;
  submitSuccess: boolean = false;
  submissionPending: boolean = false;

  importantFeatures = [
    { id: 1, name: 'Fast time to see options' },
    { id: 2, name: 'Be contacted about new tariff that match my needs' },
    { id: 3, name: 'Number of available tariffs' },
    { id: 4, name: 'See how my actual data is used in making recommendations' },
    { id: 5, name: 'Ability to explore tariffs by filtering' },
    { id: 6, name: 'Being able to see the different types of changes I could make' }
  ];

  get importantFeaturesArray() {
    return this.form.controls.important as FormArray;
  }

  constructor(
    private ccService: NgcCookieConsentService,
    private glowService: GlowService,
    private formBuilder: FormBuilder
  ) {

    this.form = this.formBuilder.group({
      usedTool: ['', Validators.required],
      improvements: [''],
      name: [''],
      email: [''],
      number: [''],
      contactConfirmation: ['', Validators.required],
      important: new FormArray([])
    })

    this.addCheckboxes();
  }

  ngOnInit() { }

  ngOnDestroy() { }

  private addCheckboxes() {
    this.importantFeatures.forEach(() => this.importantFeaturesArray.push(new FormControl(false)));
  }

  submit() {

    this.submissionPending = true;

    const selectedImportantFeatures = this.form.value.important.map((checked, i) => checked ? this.importantFeatures[i].name : null).filter(v => v !== null);

    let payload = {
      usedTool: this.form.controls['usedTool'].value,
      name: this.form.controls['name'].value ? this.form.controls['name'].value : '',
      email: this.form.controls['email'].value ? this.form.controls['email'].value : '',
      number: this.form.controls['number'].value ? this.form.controls['number'].value : '',
      improvements: this.form.controls['improvements'].value ? this.form.controls['improvements'].value : '',
      important: selectedImportantFeatures.join(', '),
      contactConfirmation: this.form.controls['contactConfirmation'].value
    }

    this.glowService.submitFeedback(payload).subscribe((response) => {
      console.log('submitFeedback response:', response)
      this.submitSuccess = true;
      this.sendGAEvent({ event: 'feedbackFormSubmission' })
    }, (err) => {
      console.warn("Unable to submitFeedback", err)
      this.submitSuccess = false;
      this.submissionPending = false;
    });

  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }
}