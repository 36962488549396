import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-your-data-use',
  templateUrl: './your-data-use.component.html',
  styleUrls: ['./your-data-use.component.scss']
})

export class YourDataUseComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }
}