import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class DataService {

  loadtime: any

  private TermsAnnouncer = new Subject<any>();
  terms$ = this.TermsAnnouncer.asObservable();

  constructor() { }

  announceTerms(terms: any) {
    this.TermsAnnouncer.next(terms);
  }

  setLoadtime(loadtime: any) {
    console.log('setLoadtime', )
    localStorage.setItem('loadtime', JSON.stringify(loadtime));
  }

  getLoadtime() {
    console.log('getLoadtime', this.loadtime)
    return JSON.parse(localStorage.getItem('loadtime'))
  }
}