import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GlowService } from 'src/app/glow.service';

@Component({
  selector: 'app-what-next',
  templateUrl: './what-next.component.html',
  styleUrls: ['./what-next.component.scss']
})

export class WhatNextComponent implements OnInit {

  form: FormGroup
  submitSuccess: boolean = false;
  submissionPending: boolean = false;

  constructor(
    private ccService: NgcCookieConsentService,
    private glowService: GlowService,
    private formBuilder: FormBuilder
  ) {

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
    })
  }

  ngOnInit() { }

  ngOnDestroy() { }



  submit() {

    this.submissionPending = true;

    let payload = {
      name: this.form.controls['name'].value,
      email: this.form.controls['email'].value
    }

    this.glowService.submitWhatNext(payload).subscribe((response) => {
      console.log('submitFeedback response:', response)
      this.submitSuccess = true;
      this.sendGAEvent({ event: 'contactMeFormSubmission' })
    }, (err) => {
      console.warn("Unable to submitFeedback", err)
      this.submitSuccess = false;
      this.submissionPending = false;
    });

  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params)
    }
  }
}