<div class="row card-row login-wrap-row">
    <div class="col login-wrap-col">
        <div class="row heading">
            <h6>{{"PASSWORD_RESET" | translate }}</h6>
        </div>
        <div class="row form-wrap">
            <form [formGroup]="formInfo" (ngSubmit)="submitForm()" class="login">

                <div class="row">
                    <div class="col">
                        <input type="text" nbInput fullWidth placeholder="{{'EMAIL_ADDRESS' | translate}}"
                            formControlName="username" autocorrect="off" autocapitalize="none">
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col">
                        <input type="text" nbInput fullWidth placeholder="{{'PASSWORD_RESET_TOKEN' | translate}}"
                            formControlName="token">
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col">
                        <input type="password" nbInput fullWidth placeholder="{{'PASSWORD_NEW' | translate}}"
                            formControlName="password">
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col">
                        <input type="password" nbInput fullWidth placeholder="{{'PASSWORD_CONFIRM' | translate}}"
                            formControlName="confirmPassword">
                    </div>
                </div>

                <div class="alert alert-warning alert-sm" role="alert" style="margin-top: 20px;"
                    *ngIf="!formInfo.controls.password.valid  && !formInfo.controls.password.pending && (formInfo.controls.password.dirty)">
                    <div class="alert-items" *ngIf="formInfo.get('password').errors">
                        <div class="alert-item static"
                            *ngFor="let passwordValidation of formInfo.controls.password.errors | objToKeys">
                            <!-- <div class="alert-icon-wrapper">
                            </div> -->
                            <div class="alert-text"
                                *ngIf="formInfo.controls.password.errors[passwordValidation].errMsg">
                                <nb-icon icon="alert-triangle-outline"></nb-icon>
                                {{formInfo.controls.password.errors[passwordValidation].errMsg}}
                            </div>
                            <div class="alert-text"
                                *ngIf="!formInfo.controls.password.errors[passwordValidation].errMsg && passwordValidation=='required'">
                                <nb-icon icon="alert-triangle-outline"></nb-icon>
                                Please enter a new password.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="alert alert-warning alert-sm" role="alert" style="margin-top: 20px;"
                    *ngIf="!formInfo.controls.confirmPassword.valid  && !formInfo.controls.confirmPassword.pending && (formInfo.controls.confirmPassword.dirty)">
                    <div class="alert-items" *ngIf="formInfo.get('confirmPassword').errors">
                        <div class="alert-item static"
                            *ngFor="let passwordValidation of formInfo.controls.confirmPassword.errors | objToKeys">
                            <!-- <div class="alert-icon-wrapper">
                            </div> -->
                            <div class="alert-text"
                                *ngIf="formInfo.controls.confirmPassword.errors[passwordValidation].errMsg">
                                <nb-icon icon="alert-triangle-outline"></nb-icon>
                                {{formInfo.controls.confirmPassword.errors[passwordValidation].errMsg}}
                            </div>
                            <div class="alert-text"
                                *ngIf="!formInfo.controls.confirmPassword.errors[passwordValidation].errMsg && passwordValidation=='required'">
                                <nb-icon icon="alert-triangle-outline"></nb-icon>
                                Please confirm your new password.
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row form-row err-row" *ngIf="loadObj && loadObj.loadError">
                    <div class="col">
                        <span>
                            <nb-icon icon="alert-triangle-outline"></nb-icon>{{loadObj.errorString}}
                        </span>
                    </div>
                </div>

                <div class="row form-row success-row" *ngIf="loadObj && loadObj.loaded">
                    <div class="col">
                        <span>
                            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>{{loadObj.loadedMessage}}
                        </span>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col" style="text-align: center;">
                        <button nbButton status="primary" type="submit" [disabled]="!formInfo.valid">{{"PASSWORD_RESET"
                            | translate}}</button>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col">
                        <small class="signup">{{"PASSWORD_GET_A_NEW_RESET_TOKEN_1" | translate}} <a
                                routerLink="/generate-password-token"><strong>{{"PASSWORD_GET_A_NEW_RESET_TOKEN_2" |
                                    translate}}</strong></a></small><br />
                        <small class="signup">{{"ACCOUNT_ALREADY_EXISTS_Q" | translate}} <a
                                routerLink="/login"><strong>{{"LOGIN" | translate}}</strong></a></small>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>