
  <button nbButton outline nbButtonToggle status="primary" (click)="scenarioClear()" (ngModel)="btnEV">Clear</button>
  &nbsp;
    <button nbButton outline nbButtonToggle status="primary" (click)="scenarioFlex(1)">Low</button>
    <button nbButton outline nbButtonToggle status="primary" (click)="scenarioFlex(2)">Medium</button>
    <button nbButton outline nbButtonToggle status="primary" (click)="scenarioFlex(3)">High</button>
  &nbsp;
    <button nbButton outline nbButtonToggle status="primary" (click)="scenarioEV()">EV</button>
  
  <nb-card>
  
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[0]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[1]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[2]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[3]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[4]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[5]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
            <highcharts-chart [Highcharts]="resultsChart" [options]="chartOptions[6]" [(update)]="updateChartFlag"
                style="width: 50%; height: 100px; display: block;"></highcharts-chart>
  
  </nb-card>