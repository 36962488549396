import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbSidebarModule, NbMenuModule, NbThemeModule, NbLayoutModule, NbIconModule, NbActionsModule, NbDialogModule, NbTabsetModule, NbTooltipModule, NbCheckboxModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ThemeModule } from './@theme/theme.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { HomeModule } from './page/home/home.module';
import { CompareModule } from './page/compare/compare.module';
import { FaqsModule } from './page/faqs/faqs.module';
import { ResultsModule } from './page/results/results.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModule } from './page/login/login.module';
import { VerifyModule } from './page/verify/verify.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoader } from './content.service';
import { AnalyticsService } from './@core/utils';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { GenerateResetPasswordTokenModule } from './page/generate-reset-password-token/generate-reset-password-token.module';
import { ResetPasswordModule } from './page/reset-password/reset-password.module';
import { AuthGuard } from './auth-guard.service';
import { GlowModule } from './glow.module';
import { SmartTariffModule } from './components/smart-tariff/smart-tariff.module';
import { TermsConditionsModule } from './page/terms-conditions/terms-conditions.module';
import { SupplierModule } from './page/supplier/supplier.module';
import { ProductsModule } from './page/products/products.module';
import { FeedbackModule } from './page/feedback/feedback.module';
import { CookiesModule } from './page/cookies/cookies.module';
import { WhoWeAreModule } from './page/who-we-are/who-we-are.module';
import { UnbiasedModule } from './page/unbiased/unbiased.module';
import { WhatNextModule } from './page/what-next/what-next.module';
import { GlossaryModule } from './page/glossary/glossary.module';
import { YourDataUseModule } from './page/your-data-use/your-data-use.module';
import { YourDataRightsModule } from './page/your-data-rights/your-data-rights.module';
import { StopDataCollectionModule } from './page/stop-data-collection/stop-data-collection.module';
import { ComparisonModule } from './components/comparison/comparison.module';
import { FilterService } from './filter.service';
import { StscInputRatingComponent } from './stsc-input-rating/stsc-input-rating.component';
import { TermsConditionsModalModule } from './components/terms-conditions-modal/terms-conditions-modal.module';
import { DataService } from './data.service';
import { environment } from './../environments/environment';
import { TariffDetailsModule } from './components/tariff-details/tariff-details.module';
import { CsvDownloadService } from './csv-download.service';
import { TOUTariffDetailsModule } from './components/tou-tariff-details/tou-tariff-details.module';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain
  },
  position: "top-right",
  palette: {
    popup: {
      background: 'rgba(34,43,69, 0.4)'
    },
    button: {
      background: '#fff'
    }
  },
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{htmlContent}}{{compliance}}'
  },
  elements: {
    htmlContent: `
    <div class="cookieContent">
    <h3 style="color: #fff">{{header}}</h3>
    <p style="color: #fff">{{message}} {{cookie}}</p>`,
  },
  content: {
    header: "Cookies used on the website!",
    message: "This website uses cookies to drive research and ensure you get the best experience on our website. No data will be used for marketing purposes.",
    cookie: "See our <a aria-label=\"learn more about cookies\" tabindex=\"0\" class=\"cc-link\" href=\"/cookies\">Cookie policy</a> for more information.",
    allow: "Accept all cookies",
    deny: "Refuse cookies"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    StscInputRatingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    CompareModule,
    FaqsModule,
    SmartTariffModule,
    TermsConditionsModule,
    TermsConditionsModalModule,
    TariffDetailsModule,
    TOUTariffDetailsModule,
    CookiesModule,
    WhoWeAreModule,
    UnbiasedModule,
    WhatNextModule,
    GlossaryModule,
    YourDataUseModule,
    YourDataRightsModule,
    StopDataCollectionModule,
    FeedbackModule,
    ResultsModule,
    ComparisonModule,
    LoginModule,
    GenerateResetPasswordTokenModule,
    ResetPasswordModule,
    VerifyModule,
    SupplierModule,
    ProductsModule,
    BrowserAnimationsModule,
    NbDialogModule.forRoot(),
    NbThemeModule.forRoot(),
    NbLayoutModule,
    NbTooltipModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbActionsModule,
    NbTabsetModule,
    NbCheckboxModule,
    NbIconModule,
    NbEvaIconsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ThemeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    GlowModule
  ],
  providers: [AnalyticsService, AuthGuard, FilterService, DataService, CsvDownloadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
