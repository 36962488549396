import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})

export class FaqsComponent implements OnInit {

  selectedFAQ: number = 0
  preSelectedFaqId: string;
  faqs = [
    { id: 1, question: "NO_SMART_METER_Q", answer: "NO_SMART_METER_ANS" },
    { id: 2, question: "NO_ENERGY_DISPLAY_Q", answer: 'NO_ENERGY_DISPLAY_ANS' },
    { id: 3, question: "SMART_TARIFF_Q", answer: 'SMART_TARIFF_ANS' },
    { id: 4, question: "REAL_TARIFF_Q", answer: 'REAL_TARIFF_ANS' },
    { id: 5, question: "SIGNUP_TARIFF_Q", answer: 'SIGNUP_TARIFF_ANS' },
    { id: 6, question: "DO_MYDATA_Q", answer: 'DO_MYDATA_ANS' }
  ]

  constructor(
    private ccService: NgcCookieConsentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.preSelectedFaqId = this.route.snapshot.paramMap.get('id')
    if (this.preSelectedFaqId != 'default') {
      var match = this.faqs.filter(faq => {
        return faq.id === parseInt(this.preSelectedFaqId);
      })[0];
      this.toggle(match)
    }
  }

  ngOnDestroy() { }

  toggle(faq) {
    if (this.selectedFAQ != faq) {
      this.selectedFAQ = faq
      this.sendGAEvent({
        event: 'selectFAQ',
        faqQuestion: faq.question
      })
    } else {
      this.selectedFAQ = 0
    }
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }

}