import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule, NbTabsetModule, NbTooltipModule, NbSpinnerModule, NbPopoverModule } from '@nebular/theme';
import { NbAccordionModule, NbAlertModule, NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule, NbToggleModule } from '@nebular/theme';

import { ResultsComponent } from './results.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { StscBadgeComponent } from '../../stsc-badge/stsc-badge.component';
import { StscFeaturesComponent } from '../../stsc-features/stsc-features.component';
import { StscTrustComponent } from '../../stsc-trust/stsc-trust.component';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { HighchartsChartModule } from 'highcharts-angular';
import { CustomLikeButtonComponent } from 'src/app/shared/custom-like-button';
import { SharedModule } from 'src/app/shared/shared.module';
import { MonthlyModule } from 'src/app/components/graphs/monthly/monthly.module';
import { AverageDailyModule } from 'src/app/components/graphs/average-daily/average-daily.module';


const routes: Routes = [
  {
    path: '',
    component: ResultsComponent
  }
];

@NgModule({
  declarations: [ ResultsComponent, StscBadgeComponent, StscFeaturesComponent, StscTrustComponent, CustomLikeButtonComponent ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAccordionModule,
    NbTooltipModule,
    NbAlertModule,
    NgxSliderModule,
    NbSpinnerModule,
    NbTabsetModule,
    NgbTooltipModule,
    NbToggleModule,
    Ng2SmartTableModule,
    NbPopoverModule,
    HighchartsChartModule,
    MonthlyModule,
    AverageDailyModule,
    SharedModule
  ],
  exports: [CustomLikeButtonComponent],
  entryComponents: [ StscTrustComponent, StscBadgeComponent, CustomLikeButtonComponent]
})
export class ResultsModule { }
