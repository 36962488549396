import { Component } from '@angular/core';
import { MENU_ITEMS } from './pages-menu';
import { NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './@core/utils';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { GlowService } from './glow.service';
import { FilterService } from './filter.service';
import { version } from './global-variables'

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  private popupCloseSubscription: Subscription;
  subscriptionAuth: Subscription;

  title = 'FAST';
  menu = MENU_ITEMS;
  name: string;
  url: string;
  loginStatus: string
  faqs = [
    { id: 1, question: "NO_SMART_METER_Q", answer: "NO_SMART_METER_ANS" },
    { id: 2, question: "NO_ENERGY_DISPLAY_Q", answer: 'NO_ENERGY_DISPLAY_ANS' },
    { id: 3, question: "SMART_TARIFF_Q", answer: 'SMART_TARIFF_ANS' },
    { id: 4, question: "REAL_TARIFF_Q", answer: 'REAL_TARIFF_ANS' },
    { id: 5, question: "SIGNUP_TARIFF_Q", answer: 'SIGNUP_TARIFF_ANS' },
    { id: 6, question: "DO_MYDATA_Q", answer: 'DO_MYDATA_ANS' }
  ]

  contractCategory: string = 'Contract'
  lowCarbonCategory: string = 'Low Carbon Technology'
  meterTypeCategory: string = 'Meter Type'
  priceCategory: string = 'Price'

  tariffItem: string = 'Tariff Type'
  greenItem: string = 'Green'
  contractItem: string = 'Contract length'
  exitFeeItem: string = 'Exit fee'
  haveOrConsidering: string = 'Have or considering'
  smartMeterItem: string = 'Smart meter'
  traditionalMeterItem: string = 'Traditional meter'
  guaranteeItem: string = 'Price guarantee for contract'
  paymentTypeItem: string = 'Payment types'
  priceItem: string = 'Price'

  filterActive: boolean = false

  constructor(
    private sidebarService: NbSidebarService,
    private analytics: AnalyticsService,
    private router: Router,
    private glowService: GlowService,
    private ccService: NgcCookieConsentService,
    private filterService: FilterService,
    private translate: TranslateService
  ) {

    this.subscriptionAuth = this.glowService.getAuthEvent.subscribe(authEvent => {
      console.log("Login emitted PAGES component", authEvent)
      if (authEvent == 'user:loggedIn') {
        this.name = localStorage.getItem('name');
      }
      if (authEvent == 'user:logout') {
        this.name = '';
      }
    });

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    this.translate.setDefaultLang('en-GB');
    this.translate.use('en-GB');

    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.loginStatus = localStorage.length > 0 && localStorage.getItem('id_token') ? 'logged in' : 'logged out'
      this.url = e.urlAfterRedirects
      this.sendPageViewEvent(e.urlAfterRedirects)
    });
  }

  ngOnInit() {
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
          this.sendPageViewEvent(this.router.url)
          this.analytics.trackPageViews();
        } else {
          console.log('Cookie consent denied.')
        }
      });

    this.name = localStorage.getItem('name');
  }

  ngOnDestroy() {
    this.popupCloseSubscription.unsubscribe();
    if (this.subscriptionAuth) {
      this.subscriptionAuth.unsubscribe();
    }
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params)
    }
  }

  sendPageViewEvent(url) {
    console.log('sendPageViewEvent')

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {

      let formattedUrl = url;

      // Remove anything after second slash 
      if ((url.match(/\//g) || []).length > 1) {
        formattedUrl = url.substr(0, url.lastIndexOf("/"))
      }

      // Remove any query params
      if ((formattedUrl.match(/\?/g) || []).length > 0) {
        formattedUrl = formattedUrl.substr(0, formattedUrl.lastIndexOf("?"))
      }

      // Get page name
      const words = formattedUrl.replace(/\//g, "").replaceAll("-", " ").split(" "); // Remove / and replace -
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1); // Make each word uppercase
      }

      let env = ''
      switch (window.location.hostname) {
        case 'localhost':
          env = 'dev'
          break;
        case 'alpha.smarttariffsmartcomparison.org':
        case 'beta.smarttariffsmartcomparison.org':
          env = 'uat'
          break;
        case 'smarttariffsmartcomparison.org':
          env = 'live'
          break;
      }

      console.log({ event: 'pageView', path: formattedUrl, title: words.join(" "), site: env, loginStatus: this.loginStatus, version: version })

      window.dataLayer.push({
        event: 'pageView',
        path: formattedUrl,
        title: words.join(" "),
        site: env,
        loginStatus: this.loginStatus,
        version: version
      })
    }
  }

  toggleMenuSidebar(): boolean {
    this.sidebarService.toggle(true, 'right');
    return false;
  }

  toggleFilterSidebar(): boolean {
    this.filterActive = this.filterActive ? false : true
    return false;
  }

  navigateHome(loginStatus) {
    if (loginStatus == 'logged in') {
      this.router.navigateByUrl('/results');
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  navigateFeedback() {
    this.router.navigateByUrl('/feedback');
  }

  navigateResults() {
    this.router.navigateByUrl('/results');
  }

  openCookie() {
    this.ccService.open();
  }

  doLogOut() {
    console.log("Logout attempt")
    this.glowService.logout().subscribe((authResponse) => {
      this.sendGAEvent({ event: 'login', loginStatus: 'logged out' })
      localStorage.clear();
      this.loginStatus = 'logged out'
      if (this.url == '/results') {
        this.router.navigateByUrl('/home');
      }
    }, (err) => {
      console.log("Error during logout: ", err)
      localStorage.clear();
    });
  }

  doLogIn() {
    this.router.navigateByUrl('/login');
  }

  toggleFeature(event, element, cat, item, criteria) {
    this.filterService.announceFilters({ event, element, cat, item, criteria });
  }

  changeRating(rating) {
    this.filterService.announceRating(rating);
  }

}