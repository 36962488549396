<div class="row features_heading">
    <div class="col text-center features_label_small">
        Payment
    </div>
    <div *ngIf="isEV" class="col text-center features_label_small">
        EV
    </div>
    <div *ngIf="isSolar" class="col text-center features_label_small">
        Solar
    </div>
</div>
<div class="row">
    <div class="col text-center">
        <img src="assets/is_flexible.svg" />   
    </div>
    <div *ngIf="isEV" class="col text-center">
        <img src="assets/is_ev.svg" />   
    </div>
    <div *ngIf="isSolar" class="col text-center">
        <img src="assets/is_solar.svg" />   
    </div>
</div>
<div class="row">
    <div class="col text-center features_label_small">
        {{ flexibility_label }}
    </div>
    <div *ngIf="isEV" class="col text-center features_label_small">
        Yes 
    </div>
    <div *ngIf="isSolar" class="col text-center features_label_small">
        Yes
    </div>
</div>
