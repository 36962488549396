import { Injectable } from '@angular/core';

import { tResourceReadings } from './glow.typings';

@Injectable()
export class CacheService {

    cachedData: any = {};
    constructor(){

    }

    get(key: string): Promise<any> {
        return new Promise( (resolve)=> { 
            console.log("cachedData")
            console.log(this.cachedData)
            console.log(key)

            if (this.cachedData.hasOwnProperty(key)) {
                return resolve(this.cachedData[key]); 
            } else {
                return resolve();
            }
        }); 

    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    set(key: string, value: any): void {
        this.cachedData[key] = value;
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    remove(key: string): void {
        delete this.cachedData[key];
    }
    /**
     * Checks if the a key exists in cache
     */
    has(key: string): boolean {
        return this.cachedData.hasOwnProperty(key);
    }

    //clear Cache at log out events
    clear(): void {
        this.cachedData = {};
    }

    //TO DO. make more sophisticated.  Add a cleaner.
}