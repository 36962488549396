<svg width="105px" height="21px" viewBox="0 0 105 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>stars</title>
    <g id="Results" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Results-overview" transform="translate(-1007.000000, -694.000000)" fill-rule="nonzero">
            <g id="stars" transform="translate(1007.000000, 694.825317)">
                <g (click)="onClick(1)" id="single-star-transparent">
                    <polygon id="Path" [ngClass]="{ 'star_on': value > 0 }" fill="#F2F6FF" points="0 -3.01980663e-14 19.513119 -3.01980663e-14 19.513119 19.6301686 0 19.6301686"></polygon>
                    <path d="M9.7688011,13.2756096 L12.7312696,12.4874473 L13.9799134,16.3543688 L9.7688011,13.2756096 L9.7688011,13.2756096 Z M16.5996169,8.2757047 L11.3602098,8.2757047 L9.74431789,3.27579978 L8.12842598,8.2757047 L2.88901887,8.2757047 L7.12461434,11.379094 L5.50872243,16.3789989 L9.74431789,13.3002397 L12.3640214,11.379094 L16.5996169,8.2757047 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
                <g (click)="onClick(2)" id="single-star-transparent" transform="translate(21.159000, 0.000000)">
                    <polygon id="Path" [ngClass]="{ 'star_on': value > 1 }" fill="#F2F6FF" points="1.77635684e-15 -3.01980663e-14 19.513119 -3.01980663e-14 19.513119 19.6301686 1.77635684e-15 19.6301686"></polygon>
                    <path d="M9.7688011,13.2756096 L12.7312696,12.4874473 L13.9799134,16.3543688 L9.7688011,13.2756096 L9.7688011,13.2756096 Z M16.5996169,8.2757047 L11.3602098,8.2757047 L9.74431789,3.27579978 L8.12842598,8.2757047 L2.88901887,8.2757047 L7.12461434,11.379094 L5.50872243,16.3789989 L9.74431789,13.3002397 L12.3640214,11.379094 L16.5996169,8.2757047 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
                <g (click)="onClick(3)" id="single-star-transparent" transform="translate(42.318000, 0.000000)">
                    <polygon id="Path" [ngClass]="{ 'star_on': value > 2 }" fill="#F2F6FF" points="-8.8817842e-15 -3.01980663e-14 19.513119 -3.01980663e-14 19.513119 19.6301686 -8.8817842e-15 19.6301686"></polygon>
                    <path d="M9.7688011,13.2756096 L12.7312696,12.4874473 L13.9799134,16.3543688 L9.7688011,13.2756096 L9.7688011,13.2756096 Z M16.5996169,8.2757047 L11.3602098,8.2757047 L9.74431789,3.27579978 L8.12842598,8.2757047 L2.88901887,8.2757047 L7.12461434,11.379094 L5.50872243,16.3789989 L9.74431789,13.3002397 L12.3640214,11.379094 L16.5996169,8.2757047 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
                <g (click)="onClick(4)" id="single-star-transparent" transform="translate(63.477000, 0.000000)">
                    <polygon id="Path" [ngClass]="{ 'star_on': value > 3 }" fill="#F2F6FF" points="-1.77635684e-15 -3.01980663e-14 19.513119 -3.01980663e-14 19.513119 19.6301686 -1.77635684e-15 19.6301686"></polygon>
                    <path d="M9.7688011,13.2756096 L12.7312696,12.4874473 L13.9799134,16.3543688 L9.7688011,13.2756096 L9.7688011,13.2756096 Z M16.5996169,8.2757047 L11.3602098,8.2757047 L9.74431789,3.27579978 L8.12842598,8.2757047 L2.88901887,8.2757047 L7.12461434,11.379094 L5.50872243,16.3789989 L9.74431789,13.3002397 L12.3640214,11.379094 L16.5996169,8.2757047 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
                <g (click)="onClick(5)" id="single-star-transparent" transform="translate(84.636000, 0.000000)">
                    <polygon id="Path" [ngClass]="{ 'star_on': value > 4 }" fill="#F2F6FF" points="0 0 19.513119 0 19.513119 19.6301686 0 19.6301686"></polygon>
                    <path d="M9.7688011,13.2756096 L12.7312696,12.4874473 L13.9799134,16.3543688 L9.7688011,13.2756096 L9.7688011,13.2756096 Z M16.5996169,8.2757047 L11.3602098,8.2757047 L9.74431789,3.27579978 L8.12842598,8.2757047 L2.88901887,8.2757047 L7.12461434,11.379094 L5.50872243,16.3789989 L9.74431789,13.3002397 L12.3640214,11.379094 L16.5996169,8.2757047 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
