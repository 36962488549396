import { Component, OnInit } from '@angular/core';
import { GlowService } from 'src/app/glow.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { tLoginInfo } from 'src/app/glow.typings';
import { Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../app.component.scss']
})

export class LoginComponent implements OnInit {

  loginDetailsForm: FormGroup;
  loadObj: any
  viewPassword: boolean = false

  constructor(
    private authService: GlowService,
    private router: Router,
    private ccService: NgcCookieConsentService,
    private formBuilder: FormBuilder,
    private dataService: DataService,

  ) {
    this.loginDetailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void { }

  // Attempt to login in through our User service
  doLogin() {
    // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true)
    this.loadObj = { loading: true }

    console.log("Login attempt")
    let loginDetails = this.sanitiseDetails({ ... this.loginDetailsForm.value });

    this.authService.login(loginDetails).subscribe((authResponse) => {
      if (authResponse === true) {
        console.log('successfully logged in')
        const isTempAuth = this.authService.isTempAuth();
        if (isTempAuth === true) {
          console.log("Temp auth credentials detected, going straight to home")
        }

        this.checkUserTypeGA()
      } else {

        // Unsuccessful login - try to add account
        this.authService.addAccount(loginDetails).subscribe((resp) => {
          if (resp && resp.status && resp.status == 'OK') {
            this.authService.login(loginDetails).subscribe((retryAuthResponse) => {
              if (retryAuthResponse == true) {
                this.migrateDataToSTSC()
              }
            }, (err) => {
              // errMsg = 'Invalid username or password'
              console.log("Unable to login");
              console.log('err', err)
              this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
              this.loadObj = { loading: false }
            });
          } else {
            // errMsg = 'Invalid username or password'
            console.log("Unable to add account");
            this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
            this.loadObj = { loading: false }
          }
        }, (err) => {
          // errMsg = 'Invalid username or password'
          console.log("Unable to add account");
          console.log('err', err)
          this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
          this.loadObj = { loading: false }
        });

        this.loadObj = {
          // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loading: false,
          loadError: true,
          errorString: "Invalid username or password"
        }
      }

    }, (err) => {

      // Unsuccessful login - try to add account
      this.authService.addAccount(loginDetails).subscribe((resp) => {
        if (resp && resp.status && resp.status == 'OK') {
          this.authService.login(loginDetails).subscribe((retryAuthResponse) => {
            if (retryAuthResponse == true) {
              this.migrateDataToSTSC()
            }
          }, (err) => {
            // errMsg = 'Invalid username or password'
            console.log("Unable to login");
            console.log('err', err)
            this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
          });
        } else {
          // errMsg = 'Invalid username or password'
          console.log("Unable to add account");
          this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
        }
      }, (err) => {
        // errMsg = 'Invalid username or password'
        console.log("Unable to add account");
        console.log('err', err)
        this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
      });

      // console.warn(err)
      // let errMsg = "An error has occurred"
      // if (err && err.error) {
      //   // errMsg = this.errorMessageParser.getErrorMessage(err, 'login')
      // } else if (err.valid === false) {
      //   errMsg = 'Invalid username or password'
      // }

      this.loadObj = {
        // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
        loadError: true,
        errorString: "Invalid username or password"
      }
    });
  }

  sanitiseDetails(loginDetailsForm: tLoginInfo): tLoginInfo {

    // Lowercase Username:
    if (loginDetailsForm && loginDetailsForm.username) {
      loginDetailsForm.username = loginDetailsForm.username.toLowerCase()
      loginDetailsForm.username = loginDetailsForm.username.replace(/\s+/g, '');
    }

    if (loginDetailsForm.password) {
      loginDetailsForm.password = loginDetailsForm.password.replace(/\s+$/, '') // Trailing spaces
      loginDetailsForm.password = loginDetailsForm.password.replace(/^\s+/g, '') // Spaces at the start of the string
    }

    return loginDetailsForm
  }

  migrateDataToSTSC() {
    this.authService.migrateDataToSTSC().subscribe((resp) => {
      console.log('resp', resp)
      var userActionBody = { data: { loginAt: new Date }, reference: 'UserTypeGA' }
      this.sendLoginUserAction(userActionBody)
      this.navigateToResults('Bright')
    }, (err) => {
      console.log("Unable to migrate data");
      console.log('err', err)
      this.sendGAEvent({ event: 'login', loginStatus: 'log in failed' })
    });
  }

  checkUserTypeGA() {
    console.log('checkUserTypeGA')

    this.authService.getUserActions('UserTypeGA').subscribe((userActionList) => {
      let userType;
      if (userActionList && Array.isArray(userActionList) && userActionList.length > 0 && userActionList[0].reference == "UserTypeGA") {
        userType = 'Bright'
      } else {
        userType = 'STSC'
      }
      this.navigateToResults(userType)
    }, (err) => {
      console.warn(err)
    })
  }

  navigateToResults(userType) {
    console.log('navigateToResults')

    if (userType == 'Bright') {
      this.dataService.setLoadtime({ loadtime: 1 });
      this.sendGAEvent({ event: 'login', loginStatus: 'logged in', userType: userType })
      this.router.navigateByUrl('/results');
    } else {
      this.authService.getResultLoadTime().subscribe((response) => {
        console.log('getResultLoadTime', response)
        this.dataService.setLoadtime(response);
        this.sendGAEvent({ event: 'login', loginStatus: 'logged in', userType: userType })
        this.router.navigateByUrl('/results');
      }, (error) => {
        console.warn(error)
      })
    }
  }

  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true
  }

  sendLoginUserAction(userActionBody: any) {
    console.log('sendLoginUserAction')
    this.authService.sendUserAction(userActionBody).subscribe(
      (statusSend) => {
        console.log(statusSend)
      },
      (err) => {
        console.warn(err)
      })
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }
}