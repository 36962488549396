<div style="display: block; width: 100%;" *ngIf="showResults">
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[0]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[1]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[2]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[3]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[4]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[5]" [(update)]="updateChartFlag">
    </highcharts-chart>
    <highcharts-chart *ngIf="chart" [Highcharts]="resultsChart" [options]="chart[6]" [(update)]="updateChartFlag">
    </highcharts-chart>
</div>