import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule,  ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from './pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';


const MODULES = [
    CommonModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule
];

@NgModule({
    imports: [
        ...MODULES
    ],
    declarations: [],
    exports: [
        ...MODULES
    ],
    providers: [
        FormBuilder
    ],
})
export class SharedModule {
}