<div class="row">
    <div class="col" style="text-align: left;">
        <h4>{{"FAQS" | translate }}</h4>
    </div>
</div>

<div class="row card-row info-row">
    <div class="col" style="padding:20px">
        <nb-accordion>
            <nb-accordion-item *ngFor="let faq of faqs; index as i;" [expanded]="preSelectedFaqId==faq.id" >
                <nb-accordion-item-header style="background-color: #f8f9fc; color: #6575d0;" (click)="toggle(faq)">{{faq.question| translate}}
                </nb-accordion-item-header>
                <nb-accordion-item-body style="background-color: #f8f9fc; text-align: left;">
                    <span innerHTML="{{faq.answer| translate}}"></span>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </div>
</div>

<!-- <div class="row card-row info-row">
    <div class="col">
        <nb-layout-column>
            <nb-card class="info-third" accent="info">
                <nb-card-header>{{"DATA_PRIVACY_HEADING" | translate}}</nb-card-header>
                <nb-card-body>{{"DATA_PRIVACY_TEXT" | translate}}</nb-card-body>
            </nb-card>
        </nb-layout-column>
    </div>

    <div class="col">
        <nb-layout-column>
            <nb-card class="info-third" accent="info">
                <nb-card-header>{{"CHANGING_SUPPLIER_HEADING" | translate}}</nb-card-header>
                <nb-card-body>{{"CHANGING_SUPPLIER_TEXT" | translate}}</nb-card-body>
            </nb-card>
        </nb-layout-column>
    </div>

    <div class="col">
        <nb-layout-column>
            <nb-card class="info-third" accent="info">
                <nb-card-header>{{"UNLINKING_METER_HEADING" | translate}}</nb-card-header>
                <nb-card-body>{{"UNLINKING_METER_TEXT" | translate}}</nb-card-body>
            </nb-card>
        </nb-layout-column>
    </div>
</div> -->