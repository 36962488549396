import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/home'
  },
  {
    title: 'Who We Are',
    icon: 'people-outline',
    link: '/who-we-are'
  },
  {
    title: 'FAQS',
    icon: 'message-circle-outline',
    link: '/faqs/default'
  },
  {
    title: 'Feedback',
    icon: 'edit-2-outline',
    link: '/feedback'
  },
  {
    title: 'What Next',
    icon: 'shuffle-2-outline',
    link: '/what-next'
  },
  {
    title: 'Login',
    icon: 'lock-outline',
    link: '/login'
  }
];
