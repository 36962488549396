import { Component } from "@angular/core";
import { Router } from '@angular/router';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GlowService } from "src/app/glow.service";
// import { UIConfigService } from 'app/glow-angular-ionic/services/ui-configuration.service';
// import { tLoadingElementWithSuccess } from "app/glow-angular-ionic/components/component.typings";

@Component({
    styleUrls: ['./generate-reset-password-token.component.scss', '../login/login.component.scss', '../../app.component.scss'],
    templateUrl: './generate-reset-password-token.component.html',
})

export class GenerateResetPasswordTokenComponent {
    formInfo: FormGroup;
    loadObj: any
    // loadObj: tLoadingElementWithSuccess;

    constructor(
        private formBuilder: FormBuilder,
        private authService: GlowService,
        private router: Router,
        // private uiConfigService: UIConfigService
    ) {
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false)
        this.formInfo = this.formBuilder.group({
            username: ['', Validators.required]
        });

    }

    // Attempt to login in through our User service
    submitForm() {
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true)

        this.authService.triggerForgottenPasswordProcess(this.formInfo.value).subscribe((generateRestPasswordToken) => {
            // this.loadObj.loading = false;
            console.log('generateRestPasswordToken', generateRestPasswordToken)
            if (generateRestPasswordToken.valid === true) {
                this.loadObj = { loaded: true, loadedMessage: 'Success!' }
                this.router.navigateByUrl('/reset-password');
            } else {
                this.loadObj = { loadError: true, errorString: 'An error has occurred' }
            }
        }, (err) => {
            console.warn(err)
            let errMsg = "An error has occurred"
            try {
                const errJson = err.json()
                if (errJson.error) {
                    errMsg = errJson.error
                } else if (errJson.valid === false) {
                    errMsg = 'Invalid information'
                }
            } catch (e) {
                console.warn(e)
            }
            // this.loadObj.loading = false;
            // this.loadObj.loadError = true;
            // this.loadObj.errorString = errMsg;
            this.loadObj = { loadError: true, errorString: errMsg }
        });
    }
}