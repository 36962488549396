import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbAccordionModule, NbAlertModule, NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule, NbToggleModule } from '@nebular/theme';
import { FaqsComponent } from './faqs.component';
import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { StscBadgeComponent } from '../../stsc-badge/stsc-badge.component';
// import { StscFeaturesComponent } from '../../stsc-features/stsc-features.component';
// import { StscTrustComponent } from '../../stsc-trust/stsc-trust.component';
// import { StscInputRatingComponent } from '../../stsc-input-rating/stsc-input-rating.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: FaqsComponent
  }
];

@NgModule({
  declarations: [FaqsComponent
    // , StscBadgeComponent, StscFeaturesComponent, StscTrustComponent, StscInputRatingComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAccordionModule,
    NbAlertModule,
    NgxSliderModule,
    NgbTooltipModule,
    NbToggleModule,
    SharedModule
  ],
  providers: [
    DateFormatService,
    GlowService
  ],
  entryComponents: [
    // StscTrustComponent, StscBadgeComponent
  ]
})

export class FaqsModule { }