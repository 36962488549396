<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Provide feedback</h4>
        <span>We value feedback - both positive and negative to help is improve the experience for everyone.<br />Thank
            you for taking the time to write your comments. If you prefer, send an email to <a
                href="mailto:info@smarttariffsmartcomparison.org">info@smarttariffsmartcomparison.org</a></span>
    </div>
</div>

<div class="row card-row" style="margin-top:20px">
    <div class="col" style="padding:0">
        <nb-card>
            <nb-card-header style="background-color: #F7F9FC;">How was your experience?
            </nb-card-header>
            <nb-card-body>

                <form [formGroup]="form" (ngSubmit)="submit()">

                    <div class="row form-row">
                        <div class="col">
                            <label style="display: block;">Have you used the tool to find a Smart Tariff?</label>
                            <nb-radio-group formControlName="usedTool">
                                <nb-radio value="Yes">Yes</nb-radio>
                                <nb-radio value="No">No</nb-radio>
                            </nb-radio-group>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col">
                            <label>What are the important features for you? Pick as many as you like.</label>
                            <div class="row">
                                <div class="col">
                                    <label class="multi-check" formArrayName="important"
                                        *ngFor="let item of importantFeaturesArray.controls; let i = index">
                                        <nb-checkbox [formControlName]="i" [value]="importantFeatures[i].name"
                                            status="basic">
                                            {{importantFeatures[i].name}}
                                        </nb-checkbox>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col">
                            <label>Is there anything missing or something we can improve for you?</label>
                            <textarea formControlName="improvements" nbInput fullWidth></textarea>
                        </div>
                    </div>

                    <div class="row form-row">
                        <label>If you would like to receive a followup to your feedback, please leave your
                            details</label>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <input type="text" nbInput fullWidth placeholder="{{'NAME' | translate}}"
                                formControlName="name" autocorrect="off" autocapitalize="none">
                        </div>
                        <div class="col-4">
                            <input type="text" nbInput fullWidth placeholder="{{'EMAIL_ADDRESS' | translate}}"
                                formControlName="email" autocorrect="off" autocapitalize="none">
                        </div>
                        <div class="col-4">
                            <input type="text" nbInput fullWidth placeholder="Phone number" formControlName="number"
                                autocorrect="off" autocapitalize="none">
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col">
                            <nb-checkbox formControlName="contactConfirmation" status="basic">I agree to be contacted by
                                FAST
                            </nb-checkbox>
                        </div>
                    </div>

                    <div
                        style="text-align: center; margin-top: 30px; padding: 20px; background-color: #d7e5ff; border-radius: 5px;">
                        <p>Feel free to share this tool with others; the more feedback we can get, the faster we can
                            improve it.</p>

                        <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://smarttariffsmartcomparison.org/home&title=&summary=Try this new FAST tool – it uses your own energy consumption data to find the best tariff matches.&source="
                            target="_blank"> <svg style="margin-right: 10px;" width="21px" height="21px"
                                viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="social" fill="#274BDB">
                                    <g id="post-linkedin">
                                        <path
                                            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                            id="Shape" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://smarttariffsmartcomparison.org/home"
                            target="_blank">
                            <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21"
                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="social" fill="#274BDB">
                                    <g id="post-facebook">
                                        <path
                                            d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M17,2 L17,5 L15,5 C14.4,5 14,5.4 14,6 L14,8 L17,8 L17,11 L14,11 L14,18 L11,18 L11,11 L9,11 L9,8 L11,8 L11,5.5 C11,3.6 12.6,2 14.5,2 L17,2 L17,2 Z"
                                            id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://twitter.com/intent/tweet?url=https://smarttariffsmartcomparison.org/home&text=Try this new FAST tool – it uses your own energy consumption data to find the best tariff matches."
                            target="_blank">
                            <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21"
                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="social" fill="#274BDB">
                                    <g id="post-twitter">
                                        <path
                                            d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M15.7,7.3 C15.6,11.9 12.7,15.1 8.3,15.3 C6.5,15.4 5.2,14.8 4,14.1 C5.3,14.3 7,13.8 7.9,13 C6.6,12.9 5.8,12.2 5.4,11.1 C5.8,11.2 6.2,11.1 6.5,11.1 C5.3,10.7 4.5,10 4.4,8.4 C4.7,8.6 5.1,8.7 5.5,8.7 C4.6,8.2 4,6.3 4.7,5.1 C6,6.5 7.6,7.7 10.2,7.9 C9.5,5.1 13.3,3.6 14.8,5.5 C15.5,5.4 16,5.1 16.5,4.9 C16.3,5.6 15.9,6 15.4,6.4 C15.9,6.3 16.4,6.2 16.8,6 C16.7,6.5 16.2,6.9 15.7,7.3 L15.7,7.3 Z"
                                            id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <a href="mailto:support@hildebrand.co.uk" target="_blank" style="background-color:#274BDB; border-radius: 3px; position: relative; top: 1px;" width="21px" height="21px">
                            <svg xmlns="http://www.w3.org/2000/svg" style="position: relative; left: 2px; top: 1px;" width="21px" height="21px" viewBox="0 0 30 30">
                                <g id="social" fill="#d7e5ff">
                                    <g id="email">
                                        <path
                                            d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z" 
                                            id="Shape"/>
                                    </g>
                                </g>
                            </svg>
                        </a>

                    </div>

                    <nb-alert *ngIf="submitSuccess" status="success" style="margin-top: 40px;">Your feedback has been successfully sent!</nb-alert>

                    <div class="row btn-row" style="margin-top: 40px;">
                        <div class="col" style="text-align: center;">
                            <button nbButton type="submit" status="primary" [disabled]="!form.valid || submissionPending">Submit
                                Feedback</button>
                        </div>
                    </div>
                </form>

            </nb-card-body>
        </nb-card>
    </div>
</div>