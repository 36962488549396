import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-terms-conditions-modal',
  templateUrl: './terms-conditions-modal.component.html',
  styleUrls: ['./terms-conditions-modal.component.scss']
})

export class TermsConditionsModalComponent implements OnInit {

  @Input() successCall: any;
  @Input() errorCall: any;

  constructor(
    private dialogRef: NbDialogRef<any>,
    private dataService: DataService
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }

  dismiss() {
    this.dataService.announceTerms({ accepted: false, success: this.successCall, error: this.errorCall });
    this.dialogRef.close();
  }

  accept() {
    this.dataService.announceTerms({ accepted: true, success: this.successCall, error: this.errorCall });
    this.dialogRef.close();
  }

}