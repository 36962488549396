import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-monthly-graph',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss']
})

export class MonthlyComponent implements OnInit {

  @Input() selectedResult: any
  @Input() filterResults: any
  @Input() monthlyConsumptionData: any
  @Input() evMonthlyConsumptionData: any
  @Input() selectedGraphFilter
  @Input() annualCostArr: any


  chartContainerName: string = 'containerMonthlyGraph';
  chartOptions: any;
  updateHeatMapFlag = false;
  chart: any;
  totalAnnualCost: number;

  constructor() { }

  ngOnInit(): void {
    this.initialiseChart()
    if (this.monthlyConsumptionData) {
      this.redrawGraphs()
    }
    if (this.selectedGraphFilter && this.selectedResult) {
      this.graphResults()
    }
  }

  ngOnChanges(): void {
    this.initialiseChart()
    if (this.monthlyConsumptionData || this.annualCostArr) {
      this.redrawGraphs()
    }
    if (this.selectedGraphFilter && this.selectedResult) {
      this.graphResults()
    }
  }

  private initialiseChart() {
    console.log('initialising chart');

    this.chartOptions = {
      chart: {
        reflow: true,
        animation: false,
        renderTo: this.chartContainerName
      },
      title: {
        text: 'Cost comparison'
      },
      subtitle: {
        text: 'Source: smart meter'
      },
      series: [],
      legend: { enabled: true },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false
        },
        column: {
          stacking: 'normal',
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '£{value}',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: 'Cost',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Energy',
          style: {
            color: Highcharts.getOptions().colors[0]
          },
        },
        labels: {
          format: '{value} kWh',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }]
    };

    this.chart = Highcharts.chart(this.chartContainerName, this.chartOptions);
    this.chart.showLoading();
    this.chart.redraw();
  };

  async redrawGraphs() {

    console.log('redrawGraphs')
    this.chart.showLoading();
    this.totalAnnualCost = 0;

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }

    if (this.selectedGraphFilter == 'smart' && this.evMonthlyConsumptionData) {
      this.chart.addSeries(
        {
          name: 'EV',
          yAxis: 1,
          type: 'column',
          data: this.evMonthlyConsumptionData,
          color: '#eed7ff',
          tooltip: {
            pointFormat: "EV: {point.y:,.2f} kWh"
          }
        }
      )
      this.chart.redraw();
    }

    if (this.monthlyConsumptionData) {
      this.chart.addSeries(
        {
          name: 'Electricity used',
          yAxis: 1,
          type: 'column',
          data: this.monthlyConsumptionData,
          color: '#d7e5ff',
          tooltip: {
            pointFormat: "Electricity used: {point.y:,.2f} kWh"
          }
        }
      )
      this.chart.redraw();
    }

    let monthlyCostData = JSON.parse(localStorage.getItem('monthlyCostData'));

    if (monthlyCostData) {

      monthlyCostData.forEach(element => {
        this.totalAnnualCost += element[1];
      });

      this.chart.addSeries(
        {
          name: 'Current cost',
          yAxis: 0,
          type: 'line',
          data: monthlyCostData,
          color: '#FF6C23',
          tooltip: {
            pointFormat: "Current cost: £{point.y:,.2f}"
          }
        }
      )
      this.chart.redraw();
    }

    // Need to improve this somehow
    setTimeout(() => {
      this.chart.reflow();
      if (monthlyCostData) {
        this.chart.hideLoading();
      }
    }, 100);
  }

  // Add series (data) to graph
  public graphResults() {
    console.log('graphResults - add tariff series to graph')

    this.chart.showLoading();

    this.selectedResult.forEach(element => {


      let resultData = [];
      // let selectedResultTotalAnnualCost = 0;

      resultData = element.monthlycost;
      // resultData.forEach((element) => {
      //   selectedResultTotalAnnualCost += element[1];
      // })

      // let resultAmount = (this.totalAnnualCost - Math.round(selectedResultTotalAnnualCost));

      // if (resultAmount < 0) {
      //   this.chart.setTitle({ text: 'Extra spend of £' + Math.abs(resultAmount) });
      // } else {
      //   this.chart.setTitle({ text: 'You save £' + resultAmount });
      // }

      this.chart.addSeries({
        name: element.display_name,
        yAxis: 0,
        type: 'line',
        data: resultData,
        color: '#222B45',
        tooltip: {
          pointFormat: "£{point.y:,.2f}"
        }
      })

    });

    this.chart.redraw();
    this.chart.reflow();
    this.chart.hideLoading();
  }

}