<div class="row">
    <div class="col" style="text-align: left;">
        <h4>What next</h4>
    </div>
</div>

<div class="row card-row" style="margin-top:20px">
    <div class="col" style="padding:0">
        <nb-card>
            <nb-card-header style="background-color: #F7F9FC;">Let me know when the site is live!</nb-card-header>
            <nb-card-body>

                <p>FAST is in its final phases of development; at this stage we are testing the site
                    design and functionality to make sure it works as well as possible for people with a smart meter.</p>
                <p>If you would like to be told when the site is fully operational with named suppliers and smart tariffs please
                    let us know. In the meantime, thank you for using the site.</p>
        
                <form [formGroup]="form" (ngSubmit)="submit()">

                    <div class="row form-row">
                        <div class="col-6">
                            <input type="text" nbInput fullWidth placeholder="{{'NAME' | translate}}"
                                formControlName="name" autocorrect="off" autocapitalize="none">
                        </div>
                        <div class="col-6">
                            <input type="text" nbInput fullWidth placeholder="{{'EMAIL_ADDRESS' | translate}}"
                                formControlName="email" autocorrect="off" autocapitalize="none">
                        </div>
                    </div>

                    <nb-alert *ngIf="submitSuccess" status="success" style="margin-top: 40px;">Your request has been successfully sent!</nb-alert>

                    <div class="row btn-row" style="margin-top: 40px;">
                        <div class="col" style="text-align: center;">
                            <button nbButton type="submit" status="primary" [disabled]="!form.valid  || submissionPending">Submit</button>
                        </div>
                    </div>
                </form>

            </nb-card-body>
        </nb-card>
    </div>
</div>