
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  template: `<nb-icon (click)="likeTariff()" *ngIf="!rowData.liked" class="likeTariffIcon" icon="heart-outline" status="basic"></nb-icon>
  <nb-icon (click)="unlikeTariff()" *ngIf="rowData.liked" class="unlikeTariffIcon" icon="heart" status="danger" nbTooltip="Liked"></nb-icon>`
})

export class CustomLikeButtonComponent implements OnInit {

  renderValue: string;

  @Input() value;
  @Input() rowData: any;
  @Output() save = new EventEmitter<any>();

  constructor(private ccService: NgcCookieConsentService) { }

  ngOnInit() {
    this.renderValue = this.value;
  }

  likeTariff() {
    this.rowData.liked = true
    this.sendGAEvent({ event: 'tariffLiked', tariff: this.rowData.fkid })
  }

  unlikeTariff() {
    this.rowData.liked = false
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }
}