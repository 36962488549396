import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})

export class GlossaryComponent implements OnInit {

  selectedTerm: number = 0
  preSelectedGlossaryId: string;
  glossary = [
    { id: 1, term: "Customer service ranking", def: "Based on the Citizen’s Advice Aggregate rating if this is available." },
    { id: 2, term: "DCC", def: '<p>DCC is the acronym for the Data Communications Company. The DCC is responsible for the communications infrastructure behind SMETS2 meters and migrated SMETS1 meters. Meters connected to the DCC network stay smart when you switch suppliers.</p><p>The DCC network is very secure. All energy suppliers are connected to the network which is why switching suppliers will be much quicker in future.</p>' },
    { id: 3, term: "Green rating", def: 'The green rating is determined by the fuel mix that the supplier says is the source of their energy. We also look at the wider green credentials beyond just the source of energy.' },
    { id: 4, term: "In home display", def: 'The in-home display is a portable hand-held device which is securely paired to your smart meter and shows your energy use information in near-real time, both in kWh and in pounds and pence. In-home displays are offered to all domestic customers at the time of installation.' },
    { id: 5, term: "Migration", def: '<p>SMETS1 meters are being migrated to the national smart meter network managed by the DCC. If your SMETS1 meters went ‘dumb’ when you switched supplier and you had to do manual meter reads again, after they are migrated they will regain all their smart functionality and send meter readings to your supplier automatically; they will also stay smart whenever you switch in future.</p><p>The migration process is all done remotely, an engineer will not need to come to your home. A firmware update will be sent to your meters and you don’t have to do anything. Your supplier should let you know when your meters are migrated.</p>' },
    { id: 6, term: "Off peak periods", def: '<p>Off peak periods are times of low energy demand; typically time of use tariffs are lowest during off peak periods.</p><p>Electric vehicle owners can take advantage of smart time of use tariffs that make charging their vehicle during off peak periods much cheaper.</p>' },
    { id: 7, term: "Peak periods", def: 'Peak periods are when there is high demand on national energy supply. Time of use tariffs often charge a premium for electricity used at peak periods to reduce demand and ensure sufficient electricity supply for everyone.' },
    { id: 8, term: "Smart meters", def: '<p>Smart meters are the new generation of gas and electricity meters. They help you take control of your energy use; the display you get when your smart meters are installed shows you exactly what you are spending in pounds and pence - no more estimated bills or supplying meter readings.</p><p>With a smart meter you can take advantage of smart tariffs to help you save money and consumer energy in line with your lifestyle.</p><p>Note: Smart meters meters replace your traditional energy meters already installed in your home, they are NOT the Display you have in your home which shows your energy consumption in real-time.</p><p>If you’d like to learn more about smart meters Which? have written a very <a href="https://www.which.co.uk/reviews/smart-meters/article/smart-meters-explained/what-is-a-smart-meter-artlW8n6atdo" target="_blank">useful guide<a/>.</p>' },
    { id: 9, term: "Smart Rating score", def: 'This is something we are determining as part of our research. Our goal is to define a Smart score that helps you as you compare tariffs.' },
    { id: 10, term: "Smart tariff", def: '<p>Smart tariffs can help you save money if you can be flexible in how you consume energy.</p><p>For example, you can benefit if you are able to use less energy during periods of high demand (known as peak periods) and more energy during periods of low demand (known as off peak periods). People with electric vehicles or home batteries can take advantage of cheaper prices during the night, for example, by charging up when demand on the energy system is lower.</p><p>Other smart tariffs encourage you to keep your consumption down by charging you less for the energy you use below an agreed level per month; if you go over that level, your energy gets more expensive.</p><p>Most smart tariffs need you to have a smart meter.</p>' },
    { id: 11, term: "SMETS meters", def: '<p>SMETS is short for Smart Metering Equipment Technical Specifications. SMETS meters send energy use data to your supplier electronically (over the mobile network) and mean you don’t have to submit meter readings.</p><p>SMETS meters are one of two types, SMETS1 and SMETS2. You can check with your supplier to find out what type of smart meter you have.</p>' },
    { id: 12, term: "SMETS1", def: '<p>SMETS1 refers to the first generation of smart meters. If your smart meters were installed before 2020 they will be SMETS1 meters.</p><p>Millions of SMETS1 meters have already been migrated and the rest will be migrated during 2021 to the national smart meter network managed by the DCC; once that happens all connected meters will be smart even those that became ‘dumb’ when you switched suppliers. If you have an in-home display which stopped working after switching, this should work again once your meter has been migrated. You can check with your supplier directly to find out more about when your meter will be migrated.</p>' },
    { id: 13, term: "SMETS2", def: 'SMETS2 meters are second generation smart meters; they are connected to the national smart meter network managed by the DCC at the time of installation. Anyone with a SMETS2 meter or a migrated SMETS1 meter can take advantage of smart tariffs.' },
    { id: 14, term: "Time of use tariffs", def: '<p>Time of use tariffs offer different energy prices at different times of day. There are two main types:</p><p>Static : Two or more rates for electricity at fixed times of the day. For example, a lower price overnight, very high during peak periods (typically evenings) and a third rate for daytime hours.</p><p>Dynamic : The price per unit of energy changes throughout the day, typically in half-hour increments. These times and rates can also change from day to day. For example, prices may depend on the amount of wind generation that is predicted for a particular time. Energy suppliers give advance notice of what these prices will be, usually the day before.</p>' },
  ]

  constructor(
    private ccService: NgcCookieConsentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.preSelectedGlossaryId = this.route.snapshot.paramMap.get('id')
    if (this.preSelectedGlossaryId != 'default') {
      var match = this.glossary.filter(item => {
        return item.id === parseInt(this.preSelectedGlossaryId);
      })[0];
      this.toggle(match)
    }
  }

  ngOnDestroy() { }

  toggle(item) {
    if (this.selectedTerm != item) {
      this.selectedTerm = item
      this.sendGAEvent({
        event: 'selectGlossary',
        glossaryTerm: item.term
      })
    } else {
      this.selectedTerm = 0
    }
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', params)
      window.dataLayer.push(params)
    }
  }
}