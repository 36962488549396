<nb-card size="small" *ngFor="let supplier of suppliers">
    <nb-card-body (click)="viewTariffs(1)">
        <div class="media-body">
            <span class="results_label_top">Supplier</span>
            <span class="results_display_name">{{supplier.supplierName}}</span>

            <span class="results_label">Estimated cost</span>
            <span class="results_display">Accuracy {{ (supplier.billsAccuracy) | number:'1.0-0'}}
                / Annual £ {{ supplier.billsAccuracy | number:'1.0-0' }}</span>
            <span class="results_label">{{supplier.billsRating}}</span>

        </div>
</nb-card-body>
