import { Component, Input, OnInit } from '@angular/core';
import { StscEngineService } from '../../../stsc-engine.service';
import { Info } from 'luxon';
import * as Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);

@Component({
  selector: 'app-average-tou',
  templateUrl: './average-tou.component.html',
  styleUrls: ['./average-tou.component.scss']
})

export class AverageTOUComponent implements OnInit {

  @Input() touData: any

  chartContainerName: string = 'containeTOUGraph';
  chartOptions: any;
  updateHeatMapFlag = false;
  chart: any;
  totalAnnualCost: number;

  constructor() { }

  ngOnInit(): void {
    this.initialiseChart()

    if (this.touData) {
      this.graphResults()
    }
  }

  ngOnChanges(): void {
    this.initialiseChart()

    if (this.touData) {
      this.graphResults()
    }
  }

  private initialiseChart() {
    console.log('initialising chart');

    this.chartOptions = {
      chart: {
        marginTop: 0,
        marginBottom: 50,
        reflow: true,
        height: 230,
      },
      title: {
        text: null
      },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
        }
      },
      xAxis: {
        type: "category",
        labels: {
          format: "{value}"
        },
        categories: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
          '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
          '14:00', '14:30', '15:00', '15:30', '16:00', '016:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
          '22:00', '22:30', '23:00', '23:30'],
        tickInterval: 6
      },
      yAxis: {
        title: {
          text: null
        },
        // max: this.yMax,
        visible: false,
        tickAmount: 2,
        labels: {
          showFirstLabel: false,
          showLastLabel: false,
        }
      },
      tooltip: { pointFormat: '<b>{point.y} Wh</b><br/>' },

    }

    this.chart = Highcharts.chart(this.chartContainerName, this.chartOptions);
    this.chart.showLoading();
    this.chart.redraw();
  };


  // Add series (data) to graph
  public graphResults() {
    console.log('graphResults - add tou series to graph')

    this.chart.addSeries({
      name: 'TOU Average',
      yAxis: 0,
      // type: 'line',
      type: 'column',
      data: this.touData.map(x => x / 100),
      // color: '#FF6C23',
      tooltip: {
        pointFormat: "£{point.y:,.2f}"
      },
      marker: {
        enabled: false
      }
    })

    this.chart.redraw();
    this.chart.reflow();
    this.chart.hideLoading();
  }

}