<div class="row card-row login-wrap-row">
    <div class="col login-wrap-col">
        <div class="row heading">
            <h6>{{"LOGIN" | translate }}</h6>
        </div>
        <div class="row form-wrap">
            <form [formGroup]="loginDetailsForm" (ngSubmit)="doLogin()" class="login">
                <div class="row">
                    <div class="col">
                        <input type="text" nbInput fullWidth placeholder="{{'EMAIL_ADDRESS' | translate}}"
                            formControlName="username" autocorrect="off" autocapitalize="none">
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col">
                        <input [type]="viewPassword ? 'text' : 'password'" nbInput fullWidth placeholder="{{'PASSWORD' | translate}}"
                            formControlName="password">
                            <nb-icon (click)="togglePassword(viewPassword)" *ngIf="viewPassword" icon="eye-off" style="position: relative; right: 10px; top: -28px; float: right;"></nb-icon>
                            <nb-icon (click)="togglePassword(viewPassword)" *ngIf="!viewPassword" icon="eye" style="position: relative; right: 10px; top: -28px; float: right;"></nb-icon>
                        <small class="signup">
                            <a routerLink="/generate-password-token">
                                <strong>{{"PASSWORD_FORGOT_Q" | translate}}</strong>
                            </a>
                        </small>
                    </div>
                </div>
                <div class="row err-row" *ngIf="loadObj && loadObj.loadError">
                    <div class="col">
                        <span>
                            <nb-icon icon="alert-triangle-outline"></nb-icon>{{loadObj.errorString}}
                        </span>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col" style="text-align: center;">
                        <button nbButton status="primary" type="submit" [nbSpinner]="loadObj && loadObj.loading"
                            nbSpinnerStatus="info" [disabled]="!loginDetailsForm.valid || (loadObj && loadObj.loading)">{{"LOGIN" |
                            translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>