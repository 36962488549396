import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbAccordionModule, NbAlertModule, NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule, NbToggleModule } from '@nebular/theme';

import { ProductsComponent } from './products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent
  }
];

@NgModule({
  declarations: [ProductsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAccordionModule,
    NbAlertModule,
    NbToggleModule
  ]
})
export class ProductsModule { }
