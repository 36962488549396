<nb-layout-header class="home">
  <nb-layout-column class="heading">
    <h4 style="margin-bottom:40px">No gimmicks. No guessing. Your own <strong>data-driven Smart Tariffs</strong> options.</h4>
  </nb-layout-column>
  <nb-layout-column class="home-img-col" style="text-align: right;">

      <p innerHTML="{{'PROTOTYPE_MESSAGE' | translate}}"></p>

  </nb-layout-column>
</nb-layout-header>



<div class="btn-top">
  <div class="col" style="padding: 0;">
    <button nbButton status="primary" class="btn-primary" (click)="openSmartTariffModal()">{{"WHAT_IS_SMART_TARIFF" |
      translate}}</button>
    <button nbButton status="primary" class="btn-primary" (click)="openComparisonModal()">{{"LEARN_MORE" |
      translate}}</button>
  </div>
</div>

<div class="row card-row">
  <div class="col">
    <nb-card>
      <nb-card-body>
        <div class="myCF" #myCF></div>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row card-row info-row">
  <div class="col">
    <nb-layout-column>
      <img class="info-icon" src="assets/undraw_personal_settings_kihd.svg" />
      <nb-card class="info-third" accent="info">
        <nb-card-header>{{"PERSONALISED_AND_TRANSPARENT_HEADING" | translate}}</nb-card-header>
        <nb-card-body><span innerHTML="{{'PERSONALISED_AND_TRANSPARENT_TEXT' | translate}}"></span></nb-card-body>
      </nb-card>
    </nb-layout-column>
  </div>

  <div class="col">
    <nb-layout-column>
      <img class="info-icon" src="assets/undraw_data_reports_706v.svg" />
      <nb-card class="info-third" accent="info">
        <nb-card-header>{{"TRACK_SMART_TARIFF_HEADING" | translate}}</nb-card-header>
        <nb-card-body><span innerHTML="{{'TRACK_SMART_TARIFF_TEXT' | translate}}"></span></nb-card-body>
      </nb-card>
    </nb-layout-column>
  </div>

  <div class="col">
    <nb-layout-column>
      <img class="info-icon" src="assets/undraw_authentication_fsn5.svg" />
      <nb-card class="info-third" accent="info">
        <nb-card-header>{{"SECURE_HEADING" | translate}}</nb-card-header>
        <nb-card-body><span innerHTML="{{'SECURE_TEXT' | translate}}"></span></nb-card-body>
      </nb-card>
    </nb-layout-column>
  </div>
</div>