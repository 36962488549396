import { Component } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

// import { UIConfigService } from "app/services/ui.service";
// import { tEmailVerificationIncomingParams } from "app/services/app-data.typings";
// import { tLoadingElementWithSuccess } from "app/services/app-config.typings";
// import { ConfigService } from "app/services/app-config.service";
import { GlowService } from "src/app/glow.service";

@Component({
    selector: 'app-verify',
    styleUrls: ['./verify.component.scss', '../login/login.component.scss', '../../app.component.scss'],
    templateUrl: './verify.component.html',
})

export class VerifyComponent {
    loadObj: any
    // loadObj: tLoadingElementWithSuccess
    emailVerificationIncomingParams: any = { token: null, applicationId: null, usernameHash: null };
    // emailVerificationIncomingParams: tEmailVerificationIncomingParams = { token: null, applicationId: null, usernameHash: null };
    missingElements: string[] = [];
    incorrectElements: string[] = [];
    applicationConfig: any
    applicationId: string = '6dc7e58b-4f10-4897-9a6b-a0971bee1235'

    constructor(
        private authService: GlowService,
        // private configService: ConfigService,
        private route: ActivatedRoute,
        private router: Router
        // private uiConfigService: UIConfigService
    ) {
        // this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true)
        // var configuration = this.configService.loadConfig();
        // this.applicationId = configuration.application.applicationId;
        // this.applicationConfig = this.uiConfigService.applicationConfig;

        this.route.queryParams.subscribe(
            params => {
                this.missingElements = [];
                this.incorrectElements = [];
                // scope ccould also be a property, but it is not required
                const incomingRequiredProperties = ['token', 'userKey']
                const replaceCharacters = [/%0D/g, /%0A/g, /%0D%0A/g, /\r/g, /\n/g]
                const incomingValues: any = Object.keys(params).reduce((acc, incomingProperty) => {
                    const sanitisedPropertyName = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), incomingProperty)
                    if (incomingRequiredProperties.indexOf(sanitisedPropertyName) > -1 || incomingRequiredProperties.indexOf(incomingProperty) > -1) {
                        const sanitisedValue = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), params[incomingProperty] || params[sanitisedPropertyName])
                        acc[sanitisedPropertyName] = sanitisedValue;
                    }
                    return acc
                }, {})
                incomingRequiredProperties.forEach((property) => {
                    if (!incomingValues[property]) {
                        this.missingElements.push(property)
                        console.warn("Missing element: " + property);
                    }
                });
                const { token, userKey } = incomingValues;
                this.emailVerificationIncomingParams = { applicationId: this.applicationId, token, usernameHash: userKey }
                console.log('this.emailVerificationIncomingParams', this.emailVerificationIncomingParams)
                if (this.missingElements.length === 0 && this.incorrectElements.length === 0) {
                    this.submitEmailVerificationInformation()
                }
            });
    }

    submitEmailVerificationInformation() {
        console.log('submitEmailVerificationInformation')
        this.authService.verify(this.emailVerificationIncomingParams).subscribe((verificationResponse) => {
            console.log('verificationResponse', verificationResponse)
            if (verificationResponse.valid === true) {
                this.loadObj = {
                    // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                    loaded: true,
                    loadedMessage: `Your email has successfully been verified! You can now login and start using the Glass application.`
                }
            } else {
                this.loadObj = {
                    // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                    loadError: true,
                    errorString: "Your email address could not be verified."
                }
            }
        }, err => {
            console.log('err', err)
            let errorMsg;
            try {
                errorMsg = err && err.error && err.error.error
            } catch (e) {
                console.warn(e)
            }
            this.loadObj = {
                // ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
                loadError: true,
                errorString: errorMsg || "An error has occurred"
            }
        });
    }

    navigateLogin() {
        let url = this.loadObj && this.loadObj.loadedMessage ? '/login?utm_source=stsc&utm_medium=email' : '/login'
        this.router.navigateByUrl(url);
    }

}