import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'stsc-badge',
  templateUrl: './stsc-badge.component.html',
  styleUrls: ['./stsc-badge.component.scss']
})
export class StscBadgeComponent implements OnInit {

  constructor() { }

  @Input() value: number = null;
  @Input() rowData;
  displayValue: number

  ngOnInit(): void {
    console.log('this.rowData', this.rowData)
    console.log('this.value', this.value)
    if (this.rowData && this.rowData.smart_score) {
      this.displayValue = this.rowData.smart_score;
    } else {
      this.displayValue = this.value;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('this.value', this.value)
    console.log('changes', changes)
    if (this.rowData && this.rowData.smart_score) {
      this.displayValue = this.rowData.smart_score;
    } else {
      this.displayValue = this.value;
    }
  }

}
