import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbAccordionModule, NbAlertModule, NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule, NbToggleModule } from '@nebular/theme';
import { TermsConditionsModalComponent } from './terms-conditions-modal.component';
import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [TermsConditionsModalComponent],
  imports: [
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAccordionModule,
    NbAlertModule,
    NgxSliderModule,
    NgbTooltipModule,
    NbToggleModule,
    SharedModule
  ],
  providers: [
    DateFormatService,
    GlowService
  ]
})

export class TermsConditionsModalModule { }