<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Glossary</h4>
    </div>
</div>

<div class="row card-row info-row">
    <div class="col" style="padding:20px">
        <nb-accordion>
            <nb-accordion-item *ngFor="let item of glossary; index as i;" [expanded]="preSelectedGlossaryId==item.id" (click)="toggle(item)">
                <nb-accordion-item-header style="background-color: #f8f9fc; color: #6575d0;">{{item.term}}
                </nb-accordion-item-header>
                <nb-accordion-item-body style="background-color: #f8f9fc; text-align: left;">
                    <span innerHTML="{{item.def}}"></span>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </div>
</div>