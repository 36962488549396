import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbAccordionModule, NbAlertModule, NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule, NbToggleModule } from '@nebular/theme';
import { YourDataRightsComponent } from './your-data-rights.component';
import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: YourDataRightsComponent
  }
];

@NgModule({
  declarations: [YourDataRightsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAccordionModule,
    NbAlertModule,
    NgxSliderModule,
    NgbTooltipModule,
    NbToggleModule,
    SharedModule
  ],
  providers: [
    DateFormatService,
    GlowService
  ]
})

export class YourDataRightsModule { }